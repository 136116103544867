import { useState, useEffect } from 'react';
import styled from 'styled-components';
import SnippetTemplates from '../../utils/SnippetTemplates';

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`

const TemplateCardContainer = styled.div`
  width: 200px;
  border: 2px solid ${p => p.active ? 'var(--uia-black)' : '#00000000'};
  border-radius: 8px;
  box-shadow: 0 0 8px ${p => p.active ? 'var(--uia-black)' : 'var(--uia-success)'};
  background: var(--uia-light);
  user-select: none;
  cursor: pointer;
  transition: all 111ms;
`

const TemplateImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`

const TemplateTitle = styled.div`
  text-align: center;
  margin: 1rem;
`


const SnippetTemplate = (props) => {

  const [selectedTemplate, setSelectedTemplate] = useState('')

  const GetSnippetTemplates = () => {
    let availableTemplates = [];
    for (let template of SnippetTemplates) {
      if (template.availableFor.includes(props.snippetType)) {
        availableTemplates.push(template);
      }
    }

    return availableTemplates;

  }

  useEffect(() => {
    setSelectedTemplate('');
    props.onChangeTemplate([]);
  }, [props.snippetType])

  const SelectTemplate = (template) => {
    setSelectedTemplate(template.id);
    props.onChangeTemplate(template.defaultComponents);
  }

  return(
    <Container>
      <Wrapper>
        {GetSnippetTemplates().length > 0 ? 
          GetSnippetTemplates().map((type, i) => {
            return (
              <TemplateCardContainer key={i} active={(selectedTemplate === type.id)} onClick={() => SelectTemplate(type)}>
                <TemplateImage src={type.image} />
                <TemplateTitle>{type.name}</TemplateTitle>
              </TemplateCardContainer>
            )
          })
        : 
          'No templates'
        }

      </Wrapper>
    </Container>
  )
}

export default SnippetTemplate;