const Basic_Image = (editor) => {

  editor.BlockManager.add('basic-image', {
    label: 'Basic Image', 
    media: `<img src='https://www.svgrepo.com/show/86919/image.svg' />`,
    category: 'Basic',
    // name: 'Basic Text',
    content: [{
      type: 'image',
      attributes: { 
        src: 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
        width: '200',
        height: '200',
      },
    }]
  })

}

export default Basic_Image;