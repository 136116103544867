import styled from 'styled-components';
import Loader from 'react-loader-spinner';

const LoadingContainer = styled.div`
  position: absolute;
  left: 0; 
  right: 0; 
  bottom: 0; 
  top: 0;
  background: var(--brand-color);
  z-index: 1000;
  transition: all 222ms;
  opacity: ${p => p.active ? '1' : '0'};
  pointer-events: ${p => p.active ? 'all' : 'none'};
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`

const LoadingMessage = styled.div`
  margin-top: 3rem;
  font-size: 18px;
  color: white;
`

const LoadingScreen = (props) => {

  return (
    <>
      <LoadingContainer active={props.active}>
        <LoadingWrapper>
          <Loader   
            type="Grid"
            height="100px"
            width="100px"
            color="#FFFFFF"
          />
          <LoadingMessage>
            {props.message}
          </LoadingMessage>
        </LoadingWrapper>
      </LoadingContainer>
    </>
  )
}

export default LoadingScreen;