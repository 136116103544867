import { useState } from 'react';
import styled from 'styled-components';
import SnippetTypes from '../../utils/SnippetTypes';
import SnippetTemplate from '../ui/snippetTemplate';

const ContentContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-wrap: wrap;
`

const FormWrapper = styled.div`
  width: 100%;
`

const FormInput = styled.input`
  margin-bottom: 1rem;
  width: calc(100% - 1rem);
  font-size: 18px;
  padding: 0.5rem;
  border: 1px solid #33333333;
  border-radius: 8px;
  outline: none;
`

const FormDropdown = styled.select`
  margin-bottom: 1rem;
  width: calc(100%);
  font-size: 18px;
  padding: 0.5rem;
  border: 1px solid #33333333;
  border-radius: 8px;
  outline: none;
`

const InputLabel = styled.div`
  width: 100%;
  font-size: 22px;
  margin-bottom: 4px;
`

const ActionsWrapper = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;
  border-top: 1px solid #00000033;
  display: flex;
  justify-content: flex-end;
`

const ActionButton = styled.div`
  user-select: none;
  pointer-events: ${p => p.disabled ? 'none' : 'all'};
  transition: all 222ms;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 1rem;
  color: ${p => 
    p.negative ? '#CC0000' : 
      p.disabled ? '#888888' : 
        '#FFFFFF'
  };
  background: ${p => 
    p.negative ? '#FFAAAA88' : 
      p.disabled ? '#CCCCCC88' : 
        'var(--brand-color)'
  };
`

const ErrorMessage = styled.div`
  color: var(--ui-danger);
`

const NewSnippetModal = (props) => {

  const [snippetName, setSnippetName] = useState(''); 
  const [snippetType, setSnippetType] = useState(''); 
  const [snippetTemplate, setSnippetTemplate] = useState([]);
  const [ready, setReady] = useState(false);

  const Submit = () => {
    props.onSubmit({ snippetName, snippetType, snippetTemplate });
    setSnippetName('');
    setSnippetType('');
    setSnippetTemplate([])
  }

  const Cancel = () => {
    setSnippetName('');
    setSnippetType('');
    setSnippetTemplate([])
    props.onCancel();
  }

  const CheckReady = () => (snippetName !== '' && snippetType !== '' && snippetTemplate.length > 0);

  const onChangeTemplate = (components) => {
    setSnippetTemplate(components);
  }

  return (
    <ContentContainer>
      <FormWrapper>

        <InputLabel>Snippet Name</InputLabel>
        {snippetName === '' && <ErrorMessage>Must enter a snippet name.</ErrorMessage>}
        <FormInput value={snippetName} onChange={(e) => setSnippetName(e.target.value)} />

        <InputLabel>Snippet Type</InputLabel>
        {snippetType === '' && <ErrorMessage>Must select snippet type.</ErrorMessage>}
        <FormDropdown value={snippetType} onChange={(e) => setSnippetType(e.target.value)}>
          <option key="default" disabled value="">Select</option>
          {SnippetTypes.filter(a => a.enabled).map((opt, i) => {
            return <option key={i} value={opt.type}>{opt.name}</option>
          })}
        </FormDropdown>

      </FormWrapper>

      {snippetTemplate.length === 0 && <ErrorMessage>Must select snippet template.</ErrorMessage>}
      <SnippetTemplate onChangeTemplate={onChangeTemplate} snippetType={snippetType} />

      <ActionsWrapper>
        <ActionButton disabled={!CheckReady()} onClick={() => Submit()}>Submit</ActionButton>
        <ActionButton negative onClick={() => Cancel()}>Cancel</ActionButton>
      </ActionsWrapper>
    </ContentContainer>
  )
}

export default NewSnippetModal;