import styled from 'styled-components';

const ErrorContainer = styled.div`
  position: absolute;
  left: 0; 
  right: 0; 
  top: 0; 
  bottom: 0; 
  z-index: 999;
  transition: all 222ms;
  background: #F54B64;
  opacity: ${p => p.active ? '1' : '0'};
  pointer-events: ${p => p.active ? 'all' : 'none'};
`

const ErrorWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-objects: center;
`

const ErrorHeader = styled.div`
  font-size: 32px;
  font-weight: bold;
`

const ErrorMessage = styled.div`
  font-size: 24px;
`

const ErrorScreen = (props) => {
  return (
    <ErrorContainer active={props.active}>
      <ErrorWrapper>
        <ErrorHeader>Error</ErrorHeader>
        <ErrorMessage>
          {props.error}
        </ErrorMessage>
      </ErrorWrapper>
    </ErrorContainer>
  )
}

export default ErrorScreen;