const Layout_Page = (editor) => {

  editor.Components.addType('layout-page-component', {
    model: {
      defaults: {

        attributes: {
          class: 'page-container',
        },

        name: 'Page Container',

        components: [
          {
            type: 'div',
            name: 'Page Wrapper',
            attributes: { class: 'page-wrapper' },
          }
        ],

        styles: `
          .page-container {
            width: 100%;
            min-height: calc(100vh - 55px);
            display: flex;
            justify-content: center;
            align-items: stretch;
            align-content: flex-start;
            position: absolute;
            top: 0px; 
            right: 0px;
            bottom: 0px;
            left: 0px;
          }

          .page-wrapper {
            width: 100%;
            min-height: 100%;
            max-width: 1000px;
            padding: 1rem;
          }
        `

      }
    }
  })
  
  editor.BlockManager.add('layout-page', {
    label: 'Page', 
    media: `<img src='https://www.svgrepo.com/show/62437/new-page.svg' />`,
    category: 'Layout',
    content: { type: 'layout-page-component'},
  })


}

export default Layout_Page;