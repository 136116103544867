
/**
 * This adds the standard core package of CSS Selectors to the editor to style components.
 * @param {*} editor Grapes JS Editor base class object. 
 */
const CoreCSSRules = (editor) => {
  editor.Css.addRules(`
  @font-face {
    font-family: "PoppinsRegular";
    src: url(https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/Poppins-Regular.ttf);
  }

  body {
    font-family: "PoppinsRegular";
    min-height: calc(100%);
  }

  .basic-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .lx-agenda-item-container {
    width: 100%;
    margin: 0.5rem 0;
  }

  .lx-agenda-item-wrapper {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    display: flex;
    padding: 1rem;
  }

  .lx-agenda-stage-container {
    width: 300px;
  }

  .lx-agenda-stage-wrapper {
    width: 100%;
    background-color: #EFEFEF;
    padding: 1rem;
    border-radius: 4px;
  }

  .lx-agenda-stage-name {
    color: #3333FF;
    text-align: center;
    font-size: 18px;
  }

  .lx-agenda-stage-time {
    color: #3b3b3b;
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
  }

  .lx-agenda-item-content-container {
    flex-grow: 2;
    padding: 0 1rem;
    margin: 0 1rem;
    border-left: 1px solid #3b3b3b;
    border-right: 1px solid #3b3b3b33;
  }

  .lx-agenda-item-title {
    font-size: 24px;
    font-weight: bold;
    color: #3b3b3b;
    margin-bottom: 1rem;
  }

  .lx-agenda-item-speakers-text {
    color: #3b3b3b;
    margin-bottom: 0.5rem;
  }

  .lx-agenda-item-speakers-container {
    display: flex;
    flex-wrap: wrap;
  }

  .lx-agenda-item-speaker-wrapper {
    display: flex;
    align-items: center;
    align-content: center;
    width: 100%;
  }

  .lx-agenda-item-speaker-image-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid #3b3b3b;
    overflow: hidden;
    margin-right: 0.5rem;
  }

  .lx-agenda-item-speaker-image {
    width: 100%;
    height: 100%;
  }

  .lx-agenda-item-actions-container {
    width: 300px;
  }

  .lx-agenda-item-actions-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media only screen and (max-width: 900px) {
    .lx-agenda-item-wrapper {
      flex-wrap: wrap;
    }
     
    .lx-agenda-item-wrapper {
      width: 100%;
    }

    .lx-agenda-stage-container, 
    .lx-agenda-item-content-container,
    .lx-agenda-item-actions-container {
      width: 100%;
    }

    .lx-agenda-item-content-container {
      border: none;
      border-top: 1px solid #3b3b3b33;
      border-bottom: 1px solid #3b3b3b33;
      margin: 1rem 0;
      padding: 1rem 0;
      text-align: center;
    }

    .lx-agenda-item-speaker-wrapper {
      justify-content: center;
    }
  }
  `)
}

export default CoreCSSRules;