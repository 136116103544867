const Basic_ImageLink = (editor) => {
  editor.Components.addType('basic-image-link-component', {
    model: {
      defaults: {

        href: '',
        target: 'new',

        traits: [
          {
            type: 'text',
            name: 'href',
            label: 'Link',
            changeProp: true,
          },
          {
            type: 'select',
            name: 'target',
            label: 'Target',
            changeProp: true,
            options: [
              { id: 'same', name: 'Same Tab' },
              { id: 'new', name: 'New Tab' },
            ]
          },
        ],

        'script-props': ['href', 'target'],

        script: function(props) {
          let element = this;
          element.setAttribute('href', props.href);
          if (props.target === 'new') element.setAttribute('target', "_blank");
        },

        tagName: 'a',
        name: 'Image Link',
        attributes: {
          href: '',
          target: '',
          class: 'clickable-image-link',
        },

        components: [
          {

            tagName: 'img',
            type: 'image',
            name: 'Image',
            attributes: {
              src: 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
              class: 'clickable-image',
            }
          }
        ],

        styles: `
          .clickable-image-link {
            width: 200px;
            height: 200px;
          }

          .clickable-image {
            width: 100%;
            height: 100%;
          }
        `

      }
    }
  })
  editor.BlockManager.add('basic-image-link', {
    label: 'Link', 
    media: `<img src='https://www.svgrepo.com/show/30448/image.svg' />`,
    category: 'Basic',
    content: { 
      type: 'basic-image-link-component',
    },
  })
}

export default Basic_ImageLink;