const Special_IgnitePlayer = (editor) => {

  editor.Components.addType('ignite-player-component', {
    model: {
      defaults: {

        attributes: {
          class: 'player-container',
          id: 'inferno-video-player'
        },

        name: 'Ignite Player Container',

        script: function() {

          const resize = () => {

          }
          resize();

          let observer = new ResizeObserver(entries => {
            entries.forEach(mutation => {
              resize();
            })
          })

          observer.observe(this);
        },

        styles: `
          .player-container {
            width: 100%;
            min-height: 100px;
          }

          .player-wrapper {
            width: 100%;
            height: 100%;
            min-height: 100px;
          }
        `

      }
    }
  })
  
  editor.BlockManager.add('ignite-player', {
    label: 'Ignite Player', 
    media: `<img src='https://www.svgrepo.com/show/25652/video-player.svg' />`,
    category: 'Special',
    content: { type: 'ignite-player-component'},
  })


}

export default Special_IgnitePlayer;