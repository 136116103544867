const Basic_Text = (editor) => {
  editor.BlockManager.add('basic-text', {
    label: 'Basic Text', 
    media: `<img src='https://www.svgrepo.com/show/38828/text.svg' />`,
    category: 'Basic',
    // name: 'Basic Text',
    content: [
      {   
        name: 'Text',
        type: 'text' ,
        components: ['Text']
      }
    ],
  })

}

export default Basic_Text;