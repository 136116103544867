const SnippetTypes = [
  {
    name: "Category Landing Page",
    type: "CategoryLandingPage",
    baseType: "landing",
    enabled: true,
  },
  {
    name: "Disclaimer",
    type: "Disclaimer",
    baseType: "landing",
    enabled: false,
  },
  {
    name: "Email (Event Live)",
    type: "EmailReminderIsLive",
    baseType: "email",
    enabled: false,
  },
  {
    name: "Email (Event Registration Confirmation)",
    type: "EmailRegistrationAck",
    baseType: "email",
    enabled: false,
  },
  {
    name: "Email (Group Registration Confirmation)",
    type: "GroupRegistrationConfirmation",
    baseType: "email",
    enabled: true,
  },
  {
    name: "Email (Group Registration Reminder)",
    type: "GroupReminderEmail",
    baseType: "email",
    enabled: true,
  },
  {
    name: "Email (VOD Available)",
    type: "EmailReminderVOD",
    baseType: "email",
    enabled: false,
  },
  {
    name: "Login",
    type: "Login",
    baseType: "login",
    enabled: true,
  },
  {
    name: "Plain HTML",
    type: "PlainHtml",
    baseType: "document",
    enabled: true,
  },
  {
    name: "Custom Player Page",
    type: "CustomPlayerPage",
    baseType: "document",
    enabled: true,
  },
  {
    name: "Registration (Confirmation)",
    type: "UserRegistrationConfirm",
    baseType: "document",
    enabled: true,
  },
  {
    name: "Registration (Event)",
    type: "CustomUserRegistation",
    baseType: "document",
    enabled: true,
  },
  {
    name: "Registration (Group)",
    type: "CustomGroupRegistration",
    baseType: "document",
    enabled: true,
  },
 
]

export default SnippetTypes;