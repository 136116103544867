const Basic_Header = (editor) => {

  editor.BlockManager.add('basic-header', {
    label: 'Basic Header', 
    media: `<img src='https://www.svgrepo.com/show/349037/header.svg' />`,
    category: 'Basic',
    content: { 
      tagName: 'h1',
      type: 'text',
      name: 'Header',
      components: `Header`
    },
  })

}

export default Basic_Header;