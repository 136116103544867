const Basic_HorizontalCard = (editor) => {
  editor.Components.addType('basic-horizontal-card-component', {
    model: {
      defaults: {
        attributes: { class: 'h-card' },
        name: 'Card',
        tagName: 'div',

        components: [
          {
            name: 'Card Image',
            type: 'image',
            tagName: 'img',
            attributes: {
              class: 'h-card-image',
              src: 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
            },
          },
          {
            name: 'Card Section',
            tagName: 'div',
            attributes: { class: 'h-card-section' },
            components: [
              {
                name: 'Card Header',
                type: 'text',
                tagName: 'div',
                attributes: { class: 'h-card-header' },
                components: 'Header'
              }, 
              {
                name: 'Card Content',
                type: 'text',
                tagName: 'div',
                attributes: { class: 'h-card-content' },
                components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum fermentum dui ac consequat. Ut vulputate sit amet nisi eu accumsan. Nam lacinia nisl massa, at mattis metus vulputate eget.'
              }, 
              {
                name: 'Action Button',
                type: 'link',
                attributes: { class: 'h-card-action' },
                components: 'Action'
              }
            ]
          }
        ],

        styles: `
          .h-card {
            width: 100%;
            display: flex;
            background: white;
            flex-direction: row;
            border-radius: 4px;
            box-shadow: 0 1px 2px #00000033;
            overflow: hidden;
            margin-bottom: 1rem;
          }

          .h-card.h-card-reverse {
            flex-direction: row-reverse;
          }

          .h-card-image {
            width: 200px;
            object-fit: cover;
            min-height: 100%;
          }

          .h-card-section {
            display: flex;
            flex-direction: column;
            padding-left: 1rem;
            padding-right: 0;
          }

          .h-card.h-card-reverse .h-card-section {
            padding-right: 1rem;
            padding-left: 0;
          }

          .h-card-header {
            font-size: 20px;
            font-weight: bold;
            font-family: "Poppins";
            padding: 0.5rem;
          }

          .h-card.h-card-reverse .h-card-header,
          .h-card.h-card-reverse .h-card-content {
            text-align: right;
          }

          .h-card-content {
            font-size: 16px;
            font-family: "Poppins";
            flex-grow: 1;
            padding: 0.5rem;
          }

          .h-card-action {
            padding: 0.5rem 1rem;
            margin: 0.5rem;
            border-radius: 0.5rem;
            background: red;
            font-family: "Poppins";
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            user-select: none;
            cursor: pointer;
            color: white;
            align-self: flex-start;
          }

          .h-card.h-card-reverse .h-card-action {
            align-self: flex-end;
          }

          @media only screen and (max-width: 567px) {
            .h-card {
              flex-direction: column;
              align-content: stretch;
            }

            .h-card-image {
              object-fit: cover;
              width: 100%;
              height: 200px;
            }

            .h-card.h-card-reverse {
              flex-direction: column;
            }

            .h-card .h-card-header,
            .h-card .h-card-content {
              text-align: left !important;
            }

            .h-card-section {
              padding-left: 0 !important;
              padding-right: 0 !important;
            }

            .h-card-action {
              align-self: unset !important;
            }


          } 
        `
      }
    }
  })

  editor.BlockManager.add('horizontal-card', {
    label: 'Horizontal Card',
    media: `<img src='https://www.svgrepo.com/show/2771/id-card.svg' />`,
    category: 'Basic',
    content: { type: 'basic-horizontal-card-component' }
  })
}

export default Basic_HorizontalCard;