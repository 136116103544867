import styled, { keyframes } from 'styled-components';
import {darken, lighten} from 'polished';
import { useState } from 'react';

const ToggleBoxContainer = styled.div`
  max-width: 300px;
  padding: 0.5rem;
  border-radius: 8px;
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  color: var(--ui-light);
  background: var(--ui-dark);
  user-select: none;
`

const ToggleLine = styled.div`
  flex-grow: 2;
  margin: 0 1rem;
  border-bottom: 1px dotted var(--uia-light);
`

const ToggleTitle = styled.div`
  font-size: 18px;
`

const ToggleContainer = styled.div`
  position: relative;
  width: 42px;
  height: 24px;
  background: var(--ui-light);
  border-radius: 12px;
  box-shadow: inset 0 0 2px var(--ui-dark);
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
`

const ToggleIndicator = styled.div`
  position: absolute;
  left: ${p => p.active ? '18px' : '0px'};
  transition: all 222ms; 
  width: 18px;
  height: 18px;
  background: ${p => p.active ? 'var(--ui-success)' : 'var(--ui-danger)'};
  margin: 2px;
  border-radius: 100%;
  border: 1px solid var(--uia-dark);
`

const ToggleBox = (props) => {

  let [state, setState] = useState(false);

  const onToggle = () => {
    setState(!state);
  }

  return(
    <ToggleBoxContainer onClick={() => onToggle()} >
      <ToggleTitle>
        {props.title}
      </ToggleTitle>
      <ToggleLine />
      <ToggleContainer>
        <ToggleIndicator active={state} />
      </ToggleContainer>
    </ToggleBoxContainer>
  )

}

export default ToggleBox;