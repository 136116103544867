const Layout_Table = (editor) => {
  editor.Components.addType('layout-table-component', {
    model: {
      defaults: {
        name: 'Table',
        tagName: 'table',
        styles:`
          table {
            width: 100%;
            padding: 0.5rem;
            table-layout: fixed;
          }
        `
      }
    }
  });
  editor.Components.addType('layout-row-component', {
    model: {
      defaults: {
        name: 'Row',
        tagName: 'tr',
        styles:`
          tr {
          }
        `
      }
    }
  });
  editor.Components.addType('layout-cell-component', {
    model: {
      defaults: {
        'align': 'center',

        traits: [
          {
            type: 'select',
            name: 'align',
            label: 'align',
            changeProp: true,
            options: [
              { id: 'left', name: 'Left'},
              { id: 'right', name: 'Right'},
              { id: 'justify', name: 'Justify'},
              { id: 'center', name: 'Center'},
              { id: 'char', name: 'Char'}
            ]
          }
        ],
        
        'script-props': ['align'],

        script: function(props) {
          let align = props['align'];
          let element = this;
          element.setAttribute('align', align);
        },

        name: 'Cell',
        tagName: 'td',
        styles:`
          td {
            padding: 0.5rem;
          }
        `
      }
    }
  });


  editor.BlockManager.add('layout-table', {
    label: 'Table', 
    media: `<img src='https://www.svgrepo.com/show/14343/table.svg' />`,
    category: 'Layout',
    content: [{
      type: 'layout-table-component',
      components: [{
        tagName: 'tbody',
        draggable: false,
        components: [{
          type: 'layout-row-component',
          components: [
            {
              type: 'layout-cell-component',
              components: {type: 'text', components: 'Cell'},
            },
            {
              type: 'layout-cell-component',
              components: {type: 'text', components: 'Cell'},
            },
            {
              type: 'layout-cell-component',
              components: {type: 'text', components: 'Cell'},
            },
          ]
        }]
      }]
      
    }]
  })

  editor.BlockManager.add('layout-row', {
    label: 'Row', 
    media: `<img src='https://www.svgrepo.com/show/111851/row.svg' />`,
    category: 'Layout',
    content: [{
      type: 'layout-row-component',
      components: {
        type: 'layout-cell-component',
        components: [
          {
            type: 'layout-cell-component',
            components: {type: 'text', components: 'Cell'},
          },
          {
            type: 'layout-cell-component',
            components: {type: 'text', components: 'Cell'},
          },
          {
            type: 'layout-cell-component',
            components: {type: 'text', components: 'Cell'},
          },
        ]
      }
    }]
  })


  editor.BlockManager.add('layout-cell', {
    label: 'Cell', 
    media: `<img src='https://www.svgrepo.com/show/379848/cell-full.svg' />`,
    category: 'Layout',
    content: [{
      type: 'layout-cell-component',
      components: {type: 'text', components: 'Cell'},
    }]
  })

  
}

export default Layout_Table;