const Basic_Link = (editor) => {
  editor.Components.addType('basic-link-component', {
    model: {
      defaults: {

        href: '',
        text: 'Link',
        target: 'new',

        traits: [
          {
            type: 'text',
            name: 'href',
            label: 'Link',
            changeProp: true,
          },
          {
            type: 'text',
            name: 'text',
            label: 'Label',
            changeProp: true,
          },
          {
            type: 'select',
            name: 'target',
            label: 'Target',
            changeProp: true,
            options: [
              { id: 'same', name: 'Same Tab' },
              { id: 'new', name: 'New Tab' },
            ]
          },
        ],

        'script-props': ['href', 'text', 'target'],

        tagName: 'a',

        attributes: {
          href: '',
          target: '',
        },

        script: function(props) {
          let element = this;
          element.setAttribute('href', props.href);
          element.innerHTML = props.text;
          if (props.target === 'new') element.setAttribute('target', "_blank");
        },

      }
    }
  })
  editor.BlockManager.add('basic-link', {
    label: 'Link', 
    media: `<img src='https://www.svgrepo.com/show/84870/link.svg' />`,
    category: 'Basic',
    content: { 
      name: 'Link',
      type: 'basic-link-component',
      components: 'Link'
    },
  })
}

export default Basic_Link;