let allowedFileExtensions = [
  // Applicaiton Types
  '.pdf', 
  // Image Types
  '.jpg', '.jpeg', '.gif', '.png',
  // Video Types
  '.mp4', '.webm',
  // Audio Types
  // Other
]

export const ListS3Objects = async (clientId) => {
  let url = 'https://nh3duvdz6a.execute-api.us-east-1.amazonaws.com/Prod/api/file/'+clientId;
  // console.log("Trying URL", url);
  let data = await fetch(url).then(res => res.json())
  let files = []
  // console.log('Data', data?.Contents);
  for (let file of data.Contents) {
    // console.log('File', file);
    let extension = '.'+file.Key.split('.')[file.Key.split('.').length-1].toLowerCase();
    // console.log('Extension', extension);
    if (allowedFileExtensions.includes(extension) && file.Key.includes(clientId)) {
      files.push({
        name: file.Key.replace(clientId+'/', ''),
        src: 'https://cdn.forge.nextechar.com/'+file.Key
      })
    } else {
      // console.error('File type not allowed:', file.Key);
    }
  }
  // console.log(files);
  return files;
}

export const UploadS3Object = async (file, clientId) => new Promise(async (resolve, reject) => {
  let sanitizedName = file.name.replace(/[^a-zA-Z0-9\.\-]/gmi, "_");
  let url = 'https://nh3duvdz6a.execute-api.us-east-1.amazonaws.com/Prod/api/file/'+clientId+'/'+sanitizedName;
  let reader = new FileReader();
  try {
    console.log("reading file")
    reader.addEventListener('loadend', e => {
      console.log("Putting File to S3 at ", url);
      console.log('File Type', file.type);
      console.log('Blob', new Blob([reader.result], {type: file.type}));
      fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": file.type
        },
        body: new Blob([reader.result], {type: file.type}),
      }).then((response) => {
        let data = {
          name: file.name,
          src: `https://cdn.forge.nextechar.com/${clientId}/${sanitizedName}`
        }
        console.log('Done', data)
        resolve(data);
      }).catch(e => {
        console.log(e.message)
        reject('There was an error.', e);
      }) 
    })
    reader.readAsArrayBuffer(file);
  } catch(e) {
    console.log(e);
    reject('There was an error.', e)
  }
});