const Basic_Card = (editor) => {
  editor.Components.addType('basic-card-component', {
    model: {
      defaults: {
        attributes: { class: 'card' },
        name: 'Card',
        tagName: 'div',

        components: [
          {
            name: 'Card Image',
            type: 'image',
            tagName: 'img',
            attributes: {
              class: 'card-image',
              src: 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
            },
          },
          {
            name: 'Card Header',
            type: 'text',
            tagName: 'div',
            attributes: { class: 'card-header' },
            components: 'Header'
          }, 
          {
            name: 'Card Content',
            type: 'text',
            tagName: 'div',
            attributes: { class: 'card-content' },
            components: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum fermentum dui ac consequat. Ut vulputate sit amet nisi eu accumsan. Nam lacinia nisl massa, at mattis metus vulputate eget.'
          }, 
          {
            name: 'Action Button',
            type: 'link',
            attributes: { class: 'card-action' },
            components: 'Action'
          }
        ],

        styles: `
          .card {
            width: 320px;
            margin: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            align-content: stretch;
            background: white;
            border-radius: 4px;
            box-shadow: 0 1px 2px #00000033;
            overflow: hidden;
          }

          .card-image {
            width: 100%;
            height: 200px;
            object-fit: cover;
            font-family: "Poppins";
          }

          .card-header {
            font-size: 20px;
            font-weight: bold;
            font-family: "Poppins";
            padding: 0.5rem;
          }

          .card-content {
            font-size: 16px;
            font-family: "Poppins";
            flex-grow: 1;
            padding: 0.5rem;
          }

          .card-action {
            padding: 0.5rem;
            margin: 0.5rem;
            border-radius: 0.5rem;
            background: red;
            font-family: "Poppins";
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            user-select: none;
            cursor: pointer;
            color: white;
          }
        `
      }
    }
  })

  editor.BlockManager.add('card', {
    label: 'Card',
    media: `<img src='https://www.svgrepo.com/show/51527/id-card.svg' />`,
    category: 'Basic',
    content: { type: 'basic-card-component' }
  })
}

export default Basic_Card;