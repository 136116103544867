const Layout_FlexBox = (editor) => {
  editor.Components.addType('layout-flexbox-component', {
    model: {
      defaults: {
        attributes: { class: 'flexbox' },
        name: 'Flex Box',
        tagName: 'div',
        styles: `
          .flexbox {
            padding: 1rem;
            display: flex;
            flex: 1;
          }
        `
      }
    }
  })

  editor.BlockManager.add('layout-flexbox', {
    label: 'Flex Box',
    media: `<img src='https://www.svgrepo.com/show/156470/layout.svg' />`,
    category: 'Layout',
    content: { type: 'layout-flexbox-component' }
  })
}

export default Layout_FlexBox;