import 'grapesjs/dist/css/grapes.min.css';
import styled from 'styled-components';

import { useEffect, useRef, useState } from 'react';

import grapesjs from 'grapesjs';
import AWS from 'aws-sdk';

// Buttons

// Components
import BaseModal from './modals/baseModal';
import SaveSnippetModal from './modals/saveSnippetModal';
import CancelEditModal from './modals/cancelEditModal';
import SnippetTemplates from '../utils/SnippetTemplates';
import EditorPluginSets from '../utils/EditorPluginSets';
import { ListS3Objects, UploadS3Object } from '../utils/S3';

const Editor = (props) => {

  let { refs, state, setState } = props;
  let gjsEditor = useRef(null);
  let [modalOpen, setModalOpen] = useState(false);
  let modalRef = useRef({
    modal: SaveSnippetModal,
    title: 'Save Snippet: '+refs.get.CurrentSnippet().name,
    onSubmit: (data) => SaveSnippet(data),
    onCancel: () => setModalOpen(false)
  });

  const InitialComponent = () => {
    if (refs.get.SelectedTemplate()) {
      console.log(refs.get.SelectedTemplate());
      return refs.get.SelectedTemplate();
    }
    let defaultComponent = [{ type: 'layout-page-component' }];
    let revision = refs.get.SelectedRevision();
    if (revision && revision.snippet !== '') {
      try {
        let regex = /(?<=(<anvil style="display: none">))(.|\n)*?(?=(<\/anvil>))/gmi;
        let code = revision.snippet.match(regex)[0].trim();
        return JSON.parse(code);
      } catch(e) {
        console.error("Error loading snippet", e.message);
        return defaultComponent
      }
      // return revision.snippet;
    } else {
      console.log('no snippet');
      let snippet = refs.get.CurrentSnippet();
      for (let type of SnippetTemplates) {
        if (type.availableFor.includes(snippet.snippetType)) {
          return type.defaultComponents;
        }
      }
    }
  }

  const InitialCss = () => {
    let defaultCss = [{ type: 'layout-page-component' }]
    let revision = refs.get.SelectedRevision();
    if (revision && revision.snippet !== '') {
      try {
        let regex = /(?<=(<style>))(.|\n)*?(?=(<\/style>))/gmi;
        // console.log(revision.snippet.match(regex)[0]);
        return revision.snippet.match(regex)[0];
      } catch(e) {
        return defaultCss;
      }
      // return revision.snippet;
    } else {
      console.log('no snippet');
      return defaultCss
    }
  }

  const GetStandardUnits = () => {
    return ['px', '%', 'rem', 'em', 'vw', 'vh'];
  }

  const GetBorderStyles = () => {
    return [
      { value: 'none', name: 'None'},
      { value: 'dotted', name: 'Dotted'},
      { value: 'dashed', name: 'Dashed'},
      { value: 'solid', name: 'Solid'},
      { value: 'double', name: 'Double'},
      { value: 'groove', name: 'Groove'},
      { value: 'ridge', name: 'Ridge'},
      { value: 'inset', name: 'Inset'},
      { value: 'outset', name: 'Outset'},
      { value: 'hidden', name: 'Hidden'},
    ]
  }

  const PreprocessSnippetScripts = (data) => {
    const landingPageTypes = ['CategoryLandingPage'];
    const loginPageTypes = ['Login'];

    if (landingPageTypes.includes(refs.get.CurrentSnippet().snippetType)) {
      data = data.replaceAll('document.querySelectorAll', 'landingPageEl.querySelectorAll');
    }
    if (loginPageTypes.includes(refs.get.CurrentSnippet().snippetType)) {
      data = data.replaceAll('document.querySelectorAll', 'loginPageEl.querySelectorAll');
    }

    return data;
  }

  const SaveSnippet = (modalData) => {
    let { comment } = modalData;
    let editor = gjsEditor.current;
    let anvilComponents = JSON.stringify(editor.getComponents())
    let styles = editor.getCss();
    let html = editor.getHtml();
    let scripts = editor.getJs();

    window.editor = editor;

    console.log({scrips: scripts});

    // Remove Script Tags from HTML.
    // html.match(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi);

    // Compile the Snippet string.
    let loadHeaderFooter = ``;
    if (refs.get.CurrentSnippet().name !== 'anvil-header' && refs.get.CurrentSnippet().name !== 'anvil-footer') {
      loadHeaderFooter = `
        try {
          console.log("Fetching Snippets")
          let tools = window['VXP_Tools'];
          fetch(tools.apiUrl+'/Snippets', tools.GET).then(res => res.json()).then(snippets => {
            // console.log("Found snippets");
            for (let snx of snippets) {
              if (snx.name === 'anvil-header') {
                fetch(tools.apiUrl+'/Snippets/LatestRevisionHtml/'+snx.id, tools.GET).then(res => res.text()).then(html => {
                  // console.log("Got Header")
                  // console.log(html);
                  $(tools.root).find('.page-container').prepend($(html));
                })
              }
              if (snx.name === 'anvil-footer') {
                fetch(tools.apiUrl+'/Snippets/LatestRevisionHtml/'+snx.id, tools.GET).then(res => res.text()).then(html => {
                  // console.log("Got Footer")
                  // console.log(html);
                  $(tools.root).find('.page-container').append($(html));
                })
              }
            }
          })
        } catch(e) {
          console.error(e.message);
        }
       
      `
    }
    
    let data = `
      <anvil style="display: none">${anvilComponents}</anvil>
      <style>${styles}</style>
      ${html}
      <script defer async>
        console.log("Starting Snippet Scripts");
        ${PreprocessSnippetScripts(scripts)}
      </script>
      <script defer>
        (async () => {
          // Update VXP Tools
          try { window["VXP_Tools"].root = document } catch(e) {}
          try { window["VXP_Tools"].root = landingPageEl } catch(e) {}
          try { window["VXP_Tools"].root = loginPageEl } catch(e) {}

          // Fixes padding issue from IgniteX.
          // Make the container fill the whole page at a minimum.
          $('.content-wrapper').css('padding', '0px');
          $('.content-wrapper').css('min-height', 'calc(100vh - 55px)');

          // Removes IgniteX standard footer on all pages since it doesn't work. 🙄
          $('footer[layout="row"]').attr("style", "display: none !important;");

          // Removes the bottom margin from the section container that was used to show the footer.
          $('.wrapper > section[class="IE-FlexAuto"]').attr('style', 'margin-bottom: 0px !important;');

          // Make the container fill the whole page at a minimum.
          try {
            $(landingPageEl).find("#landing").css('min-height', 'calc(100vh - 55px)');
          } catch (e) {}

          `+loadHeaderFooter+`
          
        })();
      </script>
    `;

    console.log({finalData: data});


    setState(prev => {
      let stx = {...prev}
      stx.loadingMessage = 'Saving Snippet';
      stx.loading = true;
      return stx;
    })
    setModalOpen(false);
    let Ignite = refs.get.Ignite();
    let snippet = refs.get.CurrentSnippet();
    Ignite.AddRevision(snippet.id, `${Ignite.email}: ${comment}`, data).then(() => {
      Ignite.GetSnippets().then(snippets => {
        refs.set.Snippets(snippets);
        setState(prev => {
          let stx = {...prev}
          stx.loadingMessage = '';
          stx.loading = false;
          return stx;
        })
      })
    }).catch(e => {
      alert("There was an error saving. Please try again.")
    })
  }

  const CancelEdit = () => {
    refs.set.SelectedRevision(null);
    setState(prev => {
      let stx = {...prev}
      stx.menuOpen = true;
      return stx;
    })
  }

  const GetPlugins = () => {
    let snippet = refs.get.CurrentSnippet();
    let plugins = []
    for (let set of EditorPluginSets) {
      if (set.forTypes.includes(snippet.snippetType) || set.forTypes === '*') {
        plugins.push(...set.plugins);
      }
    }
    return plugins;
  }


  useEffect(() => {

    gjsEditor.current = grapesjs.init({
      // Indicate where to init the editor. You can also pass an HTMLElement
      container: '#gjs',
      // Get the content for the canvas directly from the element
      // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
      fromElement: false,
      assetManager: {
        embedAsBase64: true,
        autoAdd: false,
        uploadFile: async (e) => {
          // alert('Uploads are currently disabled. Contact System Admin to get your files uploaded.')
          // return;
          let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
          
          for (let file of files) {
            setState(prev => {
              let stx = {...prev};
              stx.loading = true;
              stx.loadingMessage = 'Uploading '+file.name;
              return stx;
            })
            await UploadS3Object(file, refs.get.Branding().clientId).catch(e => {
              setState(prev => {
                let stx = {...prev};
                stx.loading = false;
                stx.loadingMessage = '';
                return stx;
              })
              alert('There was a problem uploading asset.')    
            });
          }

          ListS3Objects(refs.get.Branding().clientId).then(data => {
            editor.AssetManager.add(data);
          })
          .catch(e => {
            alert('Files uploaded, but there was an error getting the sources. Please refresh.');
          })
          .finally(() => {
            setState(prev => {
              let stx = {...prev};
              stx.loading = false;
              stx.loadingMessage = '';
              return stx;
            })
          })
        },
        assets: [
          {
            name: 'Blank Profile Picture',
            src: 'https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg',
          },
          {
            name: 'Placeholder Image',
            src: 'https://www.russorizio.com/wp-content/uploads/2016/07/ef3-placeholder-image.jpg',
          }
        ]
      },
      components: InitialComponent(),
      // Size of the editor
      height: '100%',
      width: '100%',
      baseCss: `
        /* Futura */
        @font-face { font-family: 'Futura'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/Futura+Medium.otf');
        }

        /* Helvetica Neue */
        @font-face { font-family: 'Helvetica Neue'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/HelveticaNeueRegular.woff');
        }

        /* Montserrat */
        @font-face { font-family: 'Montserrat'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/Montserrat-Regular.ttf');
        }

        /* OpenSans */
        @font-face { font-family: 'OpenSans'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/OpenSans-Regular.woff');
        }
      
        /* Poppins */
        @font-face { font-family: 'Poppins'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/Poppins-Regular.ttf');
        }

        /* Proxima Nova */
        @font-face { font-family: 'Proxima Nova'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/ProximaNova-Regular.otf');
        }

        /* Roboto */
        @font-face { font-family: 'Roboto'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/Roboto-Regular.ttf');
        }

        /* Segoe UI */
        @font-face { font-family: 'Segoe UI'; font-weight: 500;
          src: url('https://inferno-event-assets.s3.us-west-2.amazonaws.com/fonts/Segoe+UI.ttf');
        }

        #landing {
          margin: -20px;
        }

        * {
          box-sizing: border-box;
        }
        html, body, [data-gjs-type=wrapper] {
          min-height: 100%;
        }
        body {
          min-height: 100%;
          background-color: #fff
        }
        [data-gjs-type=wrapper] {
          overflow: auto;
          overflow-x: hidden;
        }
        * ::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.1)
        }
        * ::-webkit-scrollbar-thumb {
          background: rgba(255, 255, 255, 0.2)
        }
        * ::-webkit-scrollbar {
          width: 10px
        }

      `,
      // protectedCss: ``,
      // canvasCss: ``,
      allowScripts: 1,
      jsInHtml: false,
      // exportWrapper: 1,
      // wrapperIsBody: 1,
      // Disable the storage manager for the moment
      storageManager: {
        id: 'gjs-',             // Prefix identifier that will be used on parameters
        type: 'local',          // Type of the storage
        autosave: false,         // Store data automatically
        autoload: false,         // Autoload stored data on init
        stepsBeforeSave: 1,     // If autosave enabled, indicates how many changes are necessary before store method is triggered
      },
      styleManager: {
        sectors: [

          {
            name: 'Positioning',
            open: false,
            buildProps: ['display', 'position', 'top', 'right', 'bottom', 'left', 'overflow'],
            properties: [
              {
                property: 'position',
                name: 'Position',
                type: 'select',
                list: [
                  { value: 'absolute', name: 'Absolute' },
                  { value: 'fixed', name: 'Fixed' },
                  { value: 'relative', name: 'Relative' },
                  { value: 'static', name: 'Static' },
                  { value: 'sticky', name: 'Sticky' },
                  { value: 'unset', name: 'Unset' },
                ]
              },
              {
                name: 'Object Fit',
                property: 'object-fit',
                type: 'select',
                list: [
                  { value: 'none', name: 'None'},
                  { value: 'contain', name: 'Contain'},
                  { value: 'cover', name: 'Cover'},
                  { value: 'fill', name: 'Fill'},
                  { value: 'scale-down', name: 'Scale Down'},
                  { value: 'unset', name: 'Unset'},
                ]
              },
              {
                name: 'Object Position',
                property: 'object-position',
                type: 'text',
                defaults: 'center center'
              }
            ]

          },
          {
            name: 'Flex',
            open: false,
            buildProps: [ 'flex-direction', 'flex-wrap', 'justify-content', 'align-items', 'align-content', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self' ]
          },
          {
            name: 'Appearance',
            open: false,
            buildProps: [ 
              'width', 
              'height', 
              'max-width', 
              'min-width', 
              'max-height', 
              'min-height',
              'z-index',
              'border-radius', 
              'border-parts',
              'margin', 
              'padding',
              'opacity',
              'box-shadow', 
              'background-color', 
              'background'
            ],
            properties: [
              { name: 'Width', property: 'width', units: GetStandardUnits() },
              { name: 'Height', property: 'height', units: GetStandardUnits() },
              { name: 'Max Width', property: 'max-width', units: GetStandardUnits() },
              { name: 'Min Width', property: 'min-width', units: GetStandardUnits() },
              { name: 'Max Height', property: 'max-height', units: GetStandardUnits() },
              { name: 'Min Height', property: 'min-height', units: GetStandardUnits() },
              { name: 'Z Index', property: 'z-index', defaults: 0, type: 'integer' },
              // {
              //   name: 'Outline', 
              //   property: 'outline',
              //   type: 'composite',
              //   properties: [
              //     {
              //       name: 'Size',
              //       type: 'integer',
              //       property: 'outline-width',
              //       units: GetStandardUnits(),
              //     },
              //     {
              //       name: 'Offset',
              //       type: 'integer',
              //       property: 'outline-offset',
              //       units: GetStandardUnits(),
              //     },
              //     {
              //       name: 'Style',
              //       type: 'select',
              //       property: 'outline-style',
              //       list: GetBorderStyles(),
              //     },
              //     {
              //       name: 'Color',
              //       type: 'color',
              //       property: 'outline-color',
              //       defaults: '',
              //     },
              //   ]
              // },
              {
                property: 'margin',
                type: 'composite',
                name: 'Margin',
                properties: [
                  { 
                    name: 'Top', 
                    type: 'integer', 
                    default: 0,
                    property: 'top', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Right', 
                    type: 'integer', 
                    default: 0,
                    property: 'right', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Left', 
                    type: 'integer', 
                    default: 0,
                    property: 'left', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Bottom', 
                    type: 'integer', 
                    default: 0,
                    property: 'bottom', 
                    units: GetStandardUnits(),
                  }
                ]
              },
              {
                property: 'padding',
                type: 'composite',
                name: 'Padding',
                properties: [
                  { 
                    name: 'Top', 
                    type: 'integer', 
                    default: 0,
                    property: 'padding-top', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Right', 
                    type: 'integer', 
                    default: 0,
                    property: 'padding-right', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Left', 
                    type: 'integer', 
                    default: 0,
                    property: 'padding-left', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Bottom', 
                    type: 'integer', 
                    default: 0,
                    property: 'padding-bottom', 
                    units: GetStandardUnits(),
                  }
                ]
              },
              {
                property: 'border-radius',
                type: 'composite',
                name: 'Border Radius',
                properties: [
                  { 
                    name: 'Top Left', 
                    type: 'integer', 
                    default: 0,
                    property: 'border-top-left-radius', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Top Right', 
                    type: 'integer', 
                    default: 0,
                    property: 'border-top-right-radius', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Bottom Right', 
                    type: 'integer', 
                    default: 0,
                    property: 'border-bottom-right-radius', 
                    units: GetStandardUnits(),
                  },
                  { 
                    name: 'Bottom Left', 
                    type: 'integer', 
                    default: 0,
                    property: 'border-bottom-left-radius', 
                    units: GetStandardUnits(),
                  }
                ]
              },
              
              {
                id: 'border-parts',
                name: 'Border Parts',
                property: 'border',
                type: 'composite',
                properties: [
                  {
                    name: 'Top',
                    property: 'border-top-style',
                    type: 'select',
                    list: GetBorderStyles()
                  },{
                    name: 'Right',
                    property: 'border-right-style',
                    type: 'select',
                    list: GetBorderStyles()
                  },{
                    name: 'Bottom',
                    property: 'border-bottom-style',
                    type: 'select',
                    list: GetBorderStyles()
                  },{
                    name: 'Left',
                    property: 'border-left-style',
                    type: 'select',
                    list: GetBorderStyles()
                  },
                ]
              },
              
              {
                name: 'Opacity',
                property: 'opacity',
                type: 'slider',
                defaults: 1,
                step: 0.01,
                max: 1,
                min: 0,
              },
            ]
          },
          {
            name: 'Typography',
            open: false,
            buildProps: [ 'font-family', 'font-size', 'font-weight', 'text-decoration', 'letter-spacing',  'line-height', 'text-align', 'color',  'text-shadow' ],
            properties: [
              {
                property: 'text-align',
                list: [
                  { value: 'left', className: 'fa fa-align-left' },
                  { value: 'center', className: 'fa fa-align-center' },
                  { value: 'right', className: 'fa fa-align-right' },
                  { value: 'justify', className: 'fa fa-align-justify' }
                ]
              },
              {
                property: 'font-family', 
                name: 'Font Familiy',
                type: 'select',
                defaults: 'Arial',
                list: [
                  {name: 'Arial', value: 'Arial'},
                  {name: 'Arial Black', value: 'Arial Black'},
                  {name: 'Brush Script MT', value: 'Brush Script MT'},
                  {name: 'Comic Sans MS', value: 'Comic Sans MS'},
                  {name: 'Courier New', value: 'Courier New'},
                  {name: 'Futura', value: 'Futura'},
                  {name: 'Georgia', value: 'Georgia'},
                  {name: 'Helvetica', value: 'Helvetica'},
                  {name: 'Helvetica Neue', value: 'Helvetica Neue'},
                  {name: 'Impact', value: 'Impact'},
                  {name: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode'},
                  {name: 'Montserrat', value: 'Montserrat'},
                  {name: 'OpenSans', value: 'OpenSans'},
                  {name: 'Poppins', value: 'Poppins'},
                  {name: 'Proxima Nova', value: 'Proxima Nova'},
                  {name: 'Roboto', value: 'Roboto'},
                  {name: 'Segoe UI', value: 'Segoe UI'},
                  {name: 'Tahoma', value: 'Tahoma'},
                  {name: 'Times New roman', value: 'Times New roman'},
                  {name: 'Trebuchet MS', value: 'Trebuchet MS'},
                  {name: 'Verdana', value: 'Verdana'},

                ]
              },
              {
                property: 'text-decoration',
                name: 'Text Decoration',
                type: 'text',
                defaults: 'none',
              }
            ]
          },
          {
            name: 'Advanced',
            open: false,
            buildProps: [
              'transition', 
              'perspective', 
              'transform',
              'filter',
              'backdrop-filter'
            ],
          }
        ]
      },
      plugins: GetPlugins()
    });

    let editor = gjsEditor.current;

    // Add Save Command
    editor.Commands.add('save-snippet', {
      run(editor, sender) {
        modalRef.current = {
          modal: SaveSnippetModal,
          title: 'Save Snippet: '+refs.get.CurrentSnippet().name,
          onSubmit: (data) => SaveSnippet(data),
          onCancel: () => setModalOpen(false)
        };
        setModalOpen(true);
      }
    })
  
    // Add Save Button
    editor.Panels.addButton('options', {
      id: 'save', 
      className: 'fa fa-save',
      command: 'save-snippet',
      attributes: { title: 'Save Snippet' },
      active: false,
    })

    // Add Cancel Edit Command
    editor.Commands.add('cancel-snippet', {
      run(editor, sender) {
        modalRef.current = {
          modal: CancelEditModal,
          title: 'Stop Editing?',
          onSubmit: () => CancelEdit(),
          onCancel: () => setModalOpen(false)
        };
        setModalOpen(true);
      }
    })
  
    // Add Cancel Edit Button
    editor.Panels.addButton('options', {
      id: 'save', 
      className: 'fa fa-window-close',
      command: 'cancel-snippet',
      attributes: { title: 'Cancel Changes' },
      active: false,
    })

    if (InitialCss()) {
      editor.Css.clear();
      editor.Css.addRules(InitialCss());
    }

    ListS3Objects(refs.get.Branding().clientId).then(data => {
      // console.log(refs.get.Branding().clientId);
      // console.log(data);
      editor.AssetManager.add(data);
    })

  }, [])

  return(
    <>
      <BaseModal 
        name={modalRef.current.title}
        active={modalOpen}
        modal={modalRef.current.modal}
        onCancel={modalRef.current.onCancel}
        onSubmit={modalRef.current.onSubmit}
      />
      <div id="gjs">
      </div>
    </>
  )
}

export default Editor;