import { useState } from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-wrap: wrap;
`


const ActionsWrapper = styled.div`
  padding-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const ActionButton = styled.div`
  user-select: none;
  pointer-events: ${p => p.disabled ? 'none' : 'all'};
  transition: all 222ms;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 1rem;
  color: ${p => 
    p.negative ? '#CC0000' : 
      p.disabled ? '#888888' : 
        '#FFFFFF'
  };
  background: ${p => 
    p.negative ? '#FFAAAA88' : 
      p.disabled ? '#CCCCCC88' : 
        'var(--brand-color)'
  };
`

const CancelEditModal = (props) => {

  const Submit = () => {
    props.onSubmit();
  }

  const Cancel = () => {
    props.onCancel();
  }

  return (
    <ContentContainer>
      <ActionsWrapper>
        <ActionButton onClick={() => Submit()}>Stop Editing</ActionButton>
        <ActionButton negative onClick={() => Cancel()}>Cancel</ActionButton>
      </ActionsWrapper>
    </ContentContainer>
  )
}

export default CancelEditModal;