import Basic_Card from "../components/editor/blocks/basic/card";
import Basic_Header from "../components/editor/blocks/basic/header";
import Basic_Headerlines from "../components/editor/blocks/basic/headerlines";
import Basic_HorizontalCard from "../components/editor/blocks/basic/horizontalCard";
import Basic_IFrame from "../components/editor/blocks/basic/iframe";
import Basic_Image from "../components/editor/blocks/basic/image";
import Basic_ImageLink from "../components/editor/blocks/basic/imageLink";
import Basic_Link from "../components/editor/blocks/basic/link";
import Layout_Table from "../components/editor/blocks/basic/table";
import Basic_Text from "../components/editor/blocks/basic/text";
import Basic_Countdown from "../components/editor/blocks/functional/countdown";
import Basic_Search from "../components/editor/blocks/functional/search";
import Layout_Collapse from "../components/editor/blocks/layout/collapse";
import Layout_Column_2Wide from "../components/editor/blocks/layout/column-2wide";
import Layout_FlexBox from "../components/editor/blocks/layout/flexbox";
import Layout_Page from "../components/editor/blocks/layout/page";
import LiveX_AgendaItem from "../components/editor/blocks/livex/agenda";
import LiveX_Header from "../components/editor/blocks/livex/banner";
import LiveX_Button from "../components/editor/blocks/livex/button";
import LiveX_Segment from "../components/editor/blocks/livex/segment";
import Special_IgnitePlayer from "../components/editor/blocks/special/ignitePlayer";
import Special_IgniteQA from "../components/editor/blocks/special/igniteQA";
import Special_Login from "../components/editor/blocks/special/login";
import CoreCSSRules from "../components/editor/css/coreCssRules";

const EditorPluginSets = [
  {
    forTypes: '*',
    plugins: [
      CoreCSSRules,
      Layout_Table,
      Basic_Header,
      Basic_Text,
      Basic_Headerlines,
      Basic_Image,
      Basic_Link,
      Basic_HorizontalCard,
      Basic_ImageLink,
      Basic_Card,
      LiveX_Header,
      LiveX_Button,
      LiveX_Segment,
    ]
  },
  {
    forTypes: [
      'CategoryLandingPage', 'UserRegistrationConfirm', 'CustomUserRegistation', 'CustomGroupRegistration',
      'Login', 'PlainHtml', 'CustomPlayerPage'
    ],
    plugins: [
      CoreCSSRules,
      Layout_Page,
      Layout_FlexBox,
      Layout_Collapse,
      Basic_Countdown,
      Basic_Search,
      LiveX_AgendaItem,
    ]
  },
  {
    forTypes: [
      'EmailReminderIsLive', 'EmailRegistrationAck', 'GroupRegistrationConfirmation', 'GroupReminderEmail'
    ],
    plugins: [
    ]
  },
  {
    forTypes: [
      'Login',
    ],
    plugins: [
      Special_Login,
    ]
  },
  {
    forTypes: [
      'CustomPlayerPage',
    ],
    plugins: [
      Special_IgnitePlayer,
      Special_IgniteQA,
    ]
  }
]

export default EditorPluginSets;