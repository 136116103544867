const LiveX_Segment = (editor) => {
  editor.Components.addType('lx-segment-component', {
    model: {
      defaults: {

        attributes: {
          class: 'lx-segment-container',
        },

        name: 'LiveX Segment',

        components: [
          {
            type: 'div',
            attributes: { class: 'lx-segment-border '},
            name: 'Segment Border',
            components: [
              {
                type: 'div',
                attributes: { class: 'lx-segment-wrapper'},
                name: 'Segment Content',
                components: {
                  type: 'basic-text',
                  components: 'Segment'
                }
              }
            ]
          }
        ],

        styles: `
          .lx-segment-container {
            width: 100%;
            padding: 0.5rem 1rem;
          }
        
          .lx-segment-border {
            padding: 0.5rem;
            width: calc(100%);
            border-radius: 8px;
            background-color: #FFFFFF88;
            backdrop-filter: blur(10px);
          }
        
          .lx-segment-wrapper {
            background-color: #F3F3F3;
            padding: 1rem;
            border-radius: 4px;
            box-shadow: 0 0 4px #00000033;
          }
        `

      }
    }
  })
  
  editor.BlockManager.add('lx-segment', {
    label: 'LiveX Segment', 
    media: `<img src='https://www.svgrepo.com/show/342778/rectangle.svg' />`,
    category: 'LiveX',
    content: { type: 'lx-segment-component'},
  })
}

export default LiveX_Segment;