const LiveX_AgendaItem = (editor) => {
  return editor.BlockManager.add('lx-agenda-component', {
    label: 'LiveX Agenda Item', 
    media: `<img src='https://www.svgrepo.com/show/43761/agenda.svg' />`,
    category: 'LiveX',
    content: `
      <div class='lx-agenda-item-container'>
        <div class='lx-agenda-item-wrapper'>
          
          <div class='lx-agenda-stage-container'>
            <div class='lx-agenda-stage-wrapper'>
              <div class='lx-agenda-stage-name'>Stage Name</div>
              <div class='lx-agenda-stage-time'>Time</div>
            </div>
          </div>

          <div class='lx-agenda-item-content-container'>
            <div class='lx-agenda-item-content-wrapper'>
              <div class='lx-agenda-item-title'>Title</div>
              <div class='lx-agenda-item-speakers-text'>Speakers:</div>
              <div class='lx-agenda-item-speakers-container'>
                <div class='lx-agenda-item-speaker-wrapper'>
                  <div class='lx-agenda-item-speaker-image-wrapper'>
                    <img src='https://inferno-event-assets.s3.us-west-2.amazonaws.com/misc/jtools/blank-profile-picture.jpg' class='lx-agenda-item-speaker-image' />
                  </div>
                  <div class='lx-agenda-item-speaker-name'>Speaker Name</div>
                </div>
              </div>
            </div>
          </div>

          <div class='lx-agenda-item-actions-container'>
            <div class='lx-agenda-item-actions-wrapper'>
              <div class='lx-button'>View Details</div>
              <div class='lx-button blue'>Add to Schedule</div>
              <div class='lx-button red'>Remove from Schedule</div>
            </div>
          </div>

        </div>
      </div>
    `,
  })
}

export default LiveX_AgendaItem;