const Special_IgniteQA = (editor) => {

  editor.Components.addType('ignite-qa-component', {
    model: {
      defaults: {

        'placeholder': 'Ask A Question',
        traits: [
          {
            type: 'text',
            name: 'placeholder',
            label: 'Placeholder',
            changeProp: true,
          },
        ],
        
        'script-props': ['placeholder'],

        script: function(props) {
          let element = this;

          let forms = document.querySelectorAll('.inferno-form-control')
          for(let form of forms) {
            console.log('Setting Placeholder', props.placeholder);
            console.log(form);
            form.setAttribute('placeholder', props.placeholder)
          }

          let iterations = 0;
          let interval = () => setInterval(() => {
            iterations++;
            if (iterations > 1000) {
              clearTimeout(interval);
            }
            let isForge = document.querySelectorAll('[class*=gjs]');
            if (isForge.length === 0) {
              let forgeContainer = element.querySelector('.qa-forge-required');
              try {
                forgeContainer.remove();
              } catch(e) {
                clearTimeout(interval);
              }
            }
          }, 100);
          interval();

        },

        attributes: {
          class: 'qa-container',
          id: 'inferno-questions-basic'
        },

        name: 'Ignite QA Container',

        components: [
          {
            name: 'QA ',
            tagName: 'div',
            removable: false,
            draggable: false,
            copyable: false,
            attributes: { class: 'inferno-input-group qa-forge-required'},
            components: [
              {
                name: 'QA Input Group',
                tagName: 'div',
                removable: false,
                draggable: false,
                copyable: false,
                attributes: { class: 'inferno-input-group-addon qa-forge-required'}
              },
              {
                name: 'QA Input',
                tagName: 'input',
                attributes: { class: 'inferno-form-control qa-forge-required', placeholder: 'Ask A Question' },
                removable: false,
                draggable: false,
                copyable: false,
              },
              {
                name: 'QA Button Group',
                tagName: 'span',
                attributes: { class: 'inferno-input-group-btn qa-forge-required' },
                removable: false,
                draggable: false,
                copyable: false,
                components: [
                  {
                    name: 'QA Input Button',
                    tagName: 'button',
                    removable: false,
                    draggable: false,
                    copyable: false,
                    attributes: {  class: 'inferno-btn qa-forge-required'},
                    components: 'Submit',
                  },
                ]
              },
              
            ]
          },
        ],

        styles: `
          .qa-container {
            width: 100%;
            margin-top: 1rem;
          }

          .inferno-input-group {
            display: flex;
            background: #050E31;
            border-radius: 4px;
            padding: 1rem;
            width: 100%;
          }

          .inferno-form-control {
            width: 100%;
            padding: 0.5rem;
            border-radius: 4px;
            margin-right: 0.5rem;
            background: white;
            border: none;
            outline: none;
            color: #050E31;
          }

          .inferno-input-group-btn {
            color: #050E31;
          }

          .inferno-btn {
            width: 100px;
            height: 100%;
            background: white;
            color: #050E31;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            border-radius: 4px;
            border: none;
            outline: none;
          }

          .inferno-input-group-addon {
            display: none;
          }

        `

      }
    }
  })
  
  editor.BlockManager.add('ignite-qa', {
    label: 'Ignite QA', 
    media: `<img src='https://www.svgrepo.com/show/339457/question-and-answer.svg' />`,
    category: 'Special',
    content: { type: 'ignite-qa-component'},
  })


}

export default Special_IgniteQA;