const Basic_Countdown = (editor) => {
  editor.Components.addType('countdown-component', {
    model: {
      defaults: {

        'end-time': new Date().getTime()+60005000,
        'leading-zeros': 'opt1',

        traits: [
          {
            type: 'number',
            name: 'end-time',
            label: 'End Time',
            changeProp: true,
          },
          {
            type: 'select',
            name: 'leading-zeros',
            label: 'Leading Zeros',
            changeProp: true,
            options: [
              { id: 'opt1', name: 'True'},
              { id: 'opt2', name: 'False'}
            ]
          }
        ],
        
        'script-props': ['end-time', 'leading-zeros'],

        script: function(props) {
          let endTime = props['end-time'];
          let element = this;
          let x = setInterval(() => {

            // Get today's date and time
            let now = new Date().getTime();
          
            // Find the distance between now and the count down date
            let distance = endTime - now;
          
            // Time calculations for days, hours, minutes and seconds
            let leadingZeros = props['leading-zeros'] === 'opt1' ? '00' : '';
            let days = leadingZeros+Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = leadingZeros+Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = leadingZeros+Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = leadingZeros+Math.floor((distance % (1000 * 60)) / 1000);

            days = days.substring(days.length - 2, days.length);
            hours = hours.substring(hours.length - 2, hours.length);
            minutes = minutes.substring(minutes.length - 2, minutes.length);
            seconds = seconds.substring(seconds.length - 2, seconds.length);

            element.querySelector('.cd-days-number').innerHTML = days.substring(days.length - 2, days.length);
            element.querySelector('.cd-hours-number').innerHTML = hours.substring(hours.length - 2, hours.length);
            element.querySelector('.cd-minutes-number').innerHTML = minutes.substring(minutes.length - 2, minutes.length);
            element.querySelector('.cd-seconds-number').innerHTML = seconds.substring(seconds.length - 2, seconds.length);

            (days === '1' | days === '01') ? element.querySelector('.cd-days-text').innerHTML = 'Day' : element.querySelector('.cd-days-text').innerHTML = 'Days';
            (hours === '1' | hours === '01') ? element.querySelector('.cd-hours-text').innerHTML = 'Hour' : element.querySelector('.cd-hours-text').innerHTML = 'Hours';
            (minutes === '1' | minutes === '01') ? element.querySelector('.cd-minutes-text').innerHTML = 'Minute' : element.querySelector('.cd-minutes-text').innerHTML = 'Minutes';
            (seconds === '1' | seconds === '01') ? element.querySelector('.cd-seconds-text').innerHTML = 'Second' : element.querySelector('.cd-seconds-text').innerHTML = 'Seconds';

            // If the count down is finished, write some text
            if (distance < 0) {
              clearInterval(x);
            }
          }, 1000);
        },

        attributes: {
          class: 'basic-countdown-container',
        },

        name: 'Countdown Container',

        styles: `
          .basic-countdown-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding: 0.5rem;
          }

          .basic-countdown-wrapper {
            border: 1px solid white;
            background: #FFFFFF33;
            backdrop-filter: blur(10px);
            box-shadow: 0 0 8px #33333388; 
            border-radius: 8px;
            width: 100%;
            display: flex;
            max-width: 800px;
            min-height: 150px;
          }
        
          .cd-wrapper {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            padding: 1rem 0;
            color: white;
          }
        
          .cd-wrapper div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
          }
        
          .cd-days-number,
          .cd-hours-number,
          .cd-minutes-number,
          .cd-seconds-number {
            font-size: 64px;
            font-weight: bold;
          }
        
          .cd-days-text,
          .cd-hours-text,
          .cd-minutes-text,
          .cd-seconds-text {
            font-size: 18px;
          }

          @media only screen and (max-width: 569px) {
            .basic-countdown-wrapper {
              flex-wrap: wrap;
            }

            .cd-wrapper {
              padding: 0;
              width: 100%;
            }
          }

          
        `,

      }
    }
  })

  editor.BlockManager.add('basic-countdown', {
    label: 'Countdown',
    media: `
      <img src='https://www.svgrepo.com/show/45690/hourglass.svg' />`,
    category: 'Functional',
    attributes: {},
    
    content: [
      { 
        type: 'countdown-component',
        tagName: 'div',
        name: 'Countdown',
        components: [{
          tagName: 'div',
          name: 'Wrapper',
          attributes: { class: 'basic-countdown-wrapper' },
          components: `
            <div class="cd-wrapper">
              <div class="cd-days-number">00
              </div>
              <div class="cd-days-text">Days
              </div>
            </div>
            <div class="cd-wrapper">
              <div class="cd-hours-number">00
              </div>
              <div class="cd-hours-text">Hours
              </div>
            </div>
            <div class="cd-wrapper">
              <div class="cd-minutes-number">00
              </div>
              <div class="cd-minutes-text">Minutes
              </div>
            </div>
            <div class="cd-wrapper">
              <div class="cd-seconds-number">00
              </div>
              <div class="cd-seconds-text">Seconds
              </div>
            </div>
          `
        }]
      },
    ]
  })
}

export default Basic_Countdown;