import { useState } from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-wrap: wrap;
`

const FormWrapper = styled.div`
  width: 100%;
`

const FormInput = styled.input`
  margin-bottom: 1rem;
  width: calc(100% - 1rem);
  font-size: 18px;
  padding: 0.5rem;
  border: 1px solid #33333333;
  border-radius: 8px;
  outline: none;
`

const InputLabel = styled.div`
  width: 100%;
  font-size: 22px;
  margin-bottom: 4px;
`

const ActionsWrapper = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  width: 100%;
  border-top: 1px solid #00000033;
  display: flex;
  justify-content: flex-end;
`

const ActionButton = styled.div`
  user-select: none;
  pointer-events: ${p => p.disabled ? 'none' : 'all'};
  transition: all 222ms;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 8px;
  margin-left: 1rem;
  color: ${p => 
    p.negative ? '#CC0000' : 
      p.disabled ? '#888888' : 
        '#FFFFFF'
  };
  background: ${p => 
    p.negative ? '#FFAAAA88' : 
      p.disabled ? '#CCCCCC88' : 
        'var(--brand-color)'
  };
`

const SaveSnippetModal = (props) => {

  const [comment, setSnippetName] = useState(''); 

  const Submit = () => {
    props.onSubmit({ comment });
    setSnippetName('');
  }

  const Cancel = () => {
    setSnippetName('');
    props.onCancel();
  }

  const CheckReady = () => (comment !== '' );

  return (
    <ContentContainer>
      <FormWrapper>

        <InputLabel>Comment</InputLabel>
        <FormInput value={comment} onChange={(e) => setSnippetName(e.target.value)} />

      </FormWrapper>
      
      <ActionsWrapper>
        <ActionButton disabled={!CheckReady()} onClick={() => Submit()}>Save</ActionButton>
        <ActionButton negative onClick={() => Cancel()}>Cancel</ActionButton>
      </ActionsWrapper>
    </ContentContainer>
  )
}

export default SaveSnippetModal;