const LiveX_Header = (editor) => {
  editor.Components.addType('lx-banner-component', {
    model: {
      defaults: {

        attributes: {
          class: 'lx-banner-container',
        },

        name: 'LiveX Banner',

        styles: `
          .lx-banner-container {
            width: 100%;
            padding: 0.5rem 1rem;
          }
        
          .lx-banner-wrapper {
            height: 100%;
            height: auto;
            min-height: calc(5rem + 5vw);
            width: calc(100%);
            border-radius: 8px;
            box-shadow: 0 0 8px #00000088;
            background-image: url(https://www.wallpaperuse.com/wallp/5-53839_m.jpg);
            background-position: center center;
            background-size: cover;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            padding: 1rem;
            color: white;
          }
        
          .lx-banner-wrapper div {
            width: 100%;
          }

          .lx-header-text {
            font-size: 32px;
            font-weight: bold;
          }

        `

      }
    }
  })
  editor.BlockManager.add('lx-banner', {
    label: 'LiveX Banner', 
    media: `<img src='https://www.svgrepo.com/show/334876/rectangle.svg' />`,
    category: 'LiveX',
    content: [
      { 
        type: 'lx-banner-component',
        components: [
          {
            attributes: { class: 'lx-banner-wrapper'},
            tagName: 'div',
            name: 'Wrapper',
            components: [
              {
                name: 'Header',
                tagName: 'div',
                type: 'text',
                attributes: { class: 'lx-header-text' },
                components: `Header`
              },
              {
                name: 'Subtext',
                tagName: 'div',
                type: 'text',
                components: `Subtext`
              },
            ]
          }
        ]
      },
      
    ]
  })
}

export default LiveX_Header;