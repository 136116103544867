const InitializeClient = async (refs) => await new Promise(async (resolve, reject) => {
  let snippets = refs.get.Snippets();
  let forgeData = refs.get.ForgeData();
  let Ignite = refs.get.Ignite();

  if (forgeData.initialized) {
    resolve();
  }

  let templateBaseUrl = 'https://inferno-event-assets.s3.us-west-2.amazonaws.com/common/forge/templates/core/';
  let snippetsToCheckFor = [
    {name: 'anvil-header', type: 'PlainHtml', template: `${templateBaseUrl}anvil-header.html`},
    {name: 'anvil-footer', type: 'PlainHtml', template: `${templateBaseUrl}anvil-footer.html`},
    {name: 'anvil-plugins', type: 'PlainHtml', template: `${templateBaseUrl}anvil-plugins.html`},
    {name: 'anvil-config', type: 'PlainHtml', template: `${templateBaseUrl}anvil-config.html`},
  ]

  for (let chx of snippetsToCheckFor) {
    let snippet = snippets.find(a => a.name === chx.name)
    if (snippet) {
      // Get latest snippet and set config.
      forgeData[chx.name] = snippet.id;

      if (snippet.revisions.length === 0) {
        // Create first missing revision.
        console.log('Missing first revision')
        await CreateMissingSnippets(refs, snippet.id, chx.template);
      }
    } else {
      // Create new snippet and revision.
      console.log('Creating snippet for', chx);
      forgeData[chx.name] = await CreateAnvilSnippetFromTemplate(refs, chx.name, chx.type, chx.template);
      if (chx.name === 'anvil-plugins') {
        let Ignite = refs.get.Ignite();
        await fetch(Ignite.apiUrl+'/Clients/SetOverrideFooterSnippet/'+forgeData[chx.name], Ignite.POST).then(() => {
          console.log('Anvil Plugins Set on Client!');
        }).catch((err) => {
          console.log('Error setting client profile footer snippet', err);
        })
      }
    }
  }

  forgeData.initialized = true;

  await UpdateAnvilConfigSnippet(forgeData['anvil-config'], refs);

  refs.set.ForgeData(forgeData);

  console.log(forgeData);

  resolve();
})

export const GetAnvilConfig = async (refs) => await new Promise(async (resolve, reject) => {
  let snippets = refs.get.Snippets();
  let Ignite = refs.get.Ignite();

  let configSnippet = snippets.find(a => a.name === 'anvil-config')
  if (configSnippet) {
    // Get latest snippet and set config.
    if (configSnippet.revisions.length === 0) {
      await InitializeClient(refs);
      resolve(refs.get.ForgeData());
    } else {
      let revisions = configSnippet.revisions.sort((a, b) => new Date(b.revisionDate) - new Date(a.revisionDate));
      let data = await Ignite.GetSnippetByRevision(revisions[0].id);
      let json = JSON.parse(data.snippet.toString());
      resolve(json);
    }
  } else {
    // Return pre-defined config.
    resolve(refs.get.ForgeData());
  }

})

export const UpdateAnvilConfigSnippet = async (snippetId, refs) => await new Promise(async (resolve, reject) => {
  let Ignite = refs.get.Ignite();
  try {
    console.log('Updating Anvil Config', refs.get.ForgeData());
    await Ignite.AddRevision(snippetId, "Updated by Forge", refs.get.ForgeData(), true);
    // Get a fresh list of snippets.
    refs.set.Snippets(await Ignite.GetSnippets());
    // Done.
    resolve();
  } catch(e) {
    console.error("Error updating anvil snippet", e);
    reject(null);
  }
})

const CreateMissingSnippets = async (refs, snippetId, templateUrl) => await new Promise(async (resolve, reject) => {
  try {
    // Get the snippet template from S3.
    let Ignite = refs.get.Ignite();
    let snippetData = await fetch(templateUrl).then(res => res.text());

    // Get the snippet details.
    let snippet = await Ignite.GetSnippetById(snippetId);
    console.log(snippet);
    
    // Add the first revision from the template.
    let code = await Ignite.AddRevision(snippet.id, "Created by Forge", snippetData);
    console.log(code);
    // Get a fresh list of snippets.
    refs.set.Snippets(await Ignite.GetSnippets());
    // Done.
    resolve(snippetId);

  } catch(e) {
    console.error('Failed creating missing Anvil revision: '+snippetId, e)
    reject();
  }
})

export const CreateAnvilSnippetFromTemplate = async (refs, snippetName, snippetType, templateUrl) => await new Promise(async (resolve, reject) => {
  try {
    // Get the snippet template from S3.
    let Ignite = refs.get.Ignite();
    let snippetData = await fetch(templateUrl).then(res => res.text());

    // Create the new snippet.
    let newSnippet = await Ignite.CreateSnippet(snippetName, snippetType);

    // If the new snippet creation was successful, get new snippet details.
    let newSnippetData = await Ignite.GetSnippetById(newSnippet.id);

    // Add the first revision from the template.
    await Ignite.AddRevision(newSnippetData.id, "Created by Forge", snippetData);
    // Get a fresh list of snippets.
    refs.set.Snippets(await Ignite.GetSnippets());
    // Done.
    resolve(newSnippet.id);

  } catch(e) {
    console.error('Failed creating Anvil snippet: '+snippetName, e)
    reject();
  }
})

export default InitializeClient;

