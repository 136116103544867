const Basic_Headerlines = (editor) => {
  editor.Components.addType('basic-headerlines-component', {
    model: {
      defaults: {
        attributes: { class: 'header-lines-container' },
        name: 'Headerlines Container',
        tagName: 'div',

        components: [
          {
            name: 'Horizontal Rule',
            tagName: 'div',
            attributes: { class: 'header-line' },
          },{
            name: 'Text',
            type: 'text',
            attributes: { class: 'header-line-text' },
            components: 'Header'
          },{
            name: 'Horizontal Rule',
            tagName: 'div',
            attributes: { class: 'header-line' },
          },
        ],

        styles: `
          .header-lines-container {
            display: flex;
            align-items: center;
            width: 100%;
          }

          .header-line {
            flex-grow: 1;
            height: 1px;
            background: black;
            width: 100%;
            margin: 1rem;
          }

          .header-line-text {
            flex-grow: 0;
            font-size: 22px;
            font-weight: bold;
            font-family: "Poppins";
          }
        `
      }
    }
  })

  editor.BlockManager.add('headerlines', {
    label: 'Rule with Text',
    media: `<img src='https://www.svgrepo.com/show/361201/horizontal-rule.svg' />`,
    category: 'Basic',
    content: { type: 'basic-headerlines-component' }
  })
}

export default Basic_Headerlines;