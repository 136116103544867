const LiveX_Button = (editor) => {
  editor.Components.addType('lx-button-component', {
    model: {
      defaults: {

        href: '',
        target: 'new',
        color: 'normal',

        traits: [
          {
            type: 'text',
            name: 'href',
            label: 'Link',
            changeProp: true,
          },
          {
            type: 'select',
            name: 'target',
            label: 'Target',
            changeProp: true,
            options: [
              { id: 'same', name: 'Same Tab' },
              { id: 'new', name: 'New Tab' },
            ]
          },
          {
            type: 'select',
            name: 'color', 
            label: 'Color',
            changeProp: true,
            options: [
              { id: 'normal', name: 'Normal'},
              { id: 'blue', name: 'Blue'},
              { id: 'red', name: 'Red'},
            ]
          }
        ],

        'script-props': ['href', 'target', 'color'],

        tagName: 'a',

        attributes: {
          class: 'lx-button normal',
          href: '',
          target: '',
        },

        script: function(props) {
          let element = this;
          element.setAttribute('href', props.href);
          if (props.target === 'new') element.setAttribute('target', "_blank");

          console.log(props.color, element.classList);
          if (props.color === 'normal') {
            element.classList.add(['normal']);
            element.classList.remove(['red', 'blue']);
          }
          if (props.color === 'red') {
            element.classList.add(['red']);
            element.classList.remove(['normal', 'blue']);
          }
          if (props.color === 'blue') {
            element.classList.add(['blue']);
            element.classList.remove(['red', 'normal']);
          }
        },

        components: [
          {
            type: 'text',
            content: 'Button'
          }
        ],

        styles: `
          .lx-button {
            width: 100%;
            padding: 0.5rem;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            text-align: center;
            cursor: pointer;
            background-color: white;
            color: #3b3b3b;
            text-decoration: none !important;
            border: 1px solid #3b3b3b;
            box-shadow: 0 0 4px #3b3b3b33;
          }
        
          .lx-button.blue {
            background-color: white;
            color: #3333FF;
            border: 1px solid #3333FF;
            box-shadow: 0 0 4px #3333FF33;
          }
        
          .lx-button.red {
            background-color: white;
            color: #FF3333;
            border: 1px solid #FF3333;
            box-shadow: 0 0 4px #FF333333;;
          }
        `

      }
    }
  })
  editor.BlockManager.add('lx-button', {
    label: 'LiveX Button', 
    media: `<img src='https://www.svgrepo.com/show/368574/button.svg' />`,
    category: 'LiveX',
    content: { type: 'lx-button-component'},
  })
}

export default LiveX_Button;