import { useState } from 'react';

import styled from 'styled-components';

const ModalContainer = styled.div`
  position: absolute;
  left: 0; 
  right: 0; 
  bottom: 0; 
  top: 0; 
  background: #00000088;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  opacity: ${p => p.active ? '1' : 0}; 
  pointer-events: ${p => p.active ? 'all' : 'none'};
  transition: all 222ms;

`

const ModalWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 8px #00000088;
  transition: all 222ms;
  transform: ${p => p.active ? 'scale(1)' : 'scale(0.9)'};
`

const ModalHeaderWrapper = styled.div`
  background: var(--brand-color);
  height: 56px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  color: var(--brand-background-color);
  box-shadow: 0 0 8px 4px var(--alpha-brand-color);
`

const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding-left: 1rem;
`

const ModalCloseWrapper = styled.div`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 32px;
  text-align: center;
  line-height: 0;
  cursor: pointer;
  user-select: none;
`

const ModalContentWrapper = styled.div`
  width: 100%;
  padding: 1rem;
`

const ModalCloseImage = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  pointer-events: none;
  filter: invert(1);
`

const BaseModal = (props) => {

  const onCancel = () => {
    try {
      props.onCancel();
    } catch(e) {}
  }

  const onSubmit = (data) => {
    try {
      props.onSubmit(data);
    } catch(e) {}
  }

  return(
    <ModalContainer active={props.active} onClick={() => onCancel()}>
      <ModalWrapper active={props.active} onClick={(e) => e.stopPropagation()}>
        <ModalHeaderWrapper>
          <ModalHeader>
            {props.name}
          </ModalHeader>
          <ModalCloseWrapper onClick={() => onCancel()}>
            <ModalCloseImage src="https://www.svgrepo.com/show/315851/close.svg" />
          </ModalCloseWrapper>
        </ModalHeaderWrapper>
        <ModalContentWrapper>
          <props.modal 
            onCancel={() => onCancel()}
            onSubmit={(data) => onSubmit(data)}
          />
        </ModalContentWrapper>
      </ModalWrapper>
    </ModalContainer>
  )

}

export default BaseModal;