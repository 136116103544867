const Basic_Search = (editor) => {

  editor.Components.addType('basic-search-input', {
    model: {
      defaults: {
        name: 'Input',
        tagName: 'input',
        
        'placeholder': 'Search',

        traits: [
          {
            type: 'text',
            name: 'placeholder',
            label: 'Placeholder',
            changeProp: true,
          }
        ],
        
        'script-props': ['placeholder'],

        attributes: { class: 'search-input' },

        script: function(props) {
          let element = this;
          element.setAttribute('placeholder', props.placeholder);
        },
      }
    }
  })

  editor.Components.addType('basic-search-component', {
    model: {
      defaults: {

        attributes: { class: 'search-wrapper' },
        name: 'Search',
        tagName: 'div',

        components: [
          {
            name: 'Icon',
            tagName: 'img',
            attributes: { 
              class: 'search-image',
              src: 'https://www.svgrepo.com/show/18282/search.svg' 
            },
          },
          {
            type: 'basic-search-input'
          }
        ],

        styles: `
          .search-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            align-content: center;
            border: 1px solid #33333333;
            border-radius: 4px;
            box-shadow: 0 0 4px #333333;
            background: white;
            padding-left: 0.5rem;
          }

          .search-image {
            width: 24px;
            height: 24px;
            filter: invert(0.5);
          }

          .search-input {
            padding: 0.25rem;
            font-size: 18px;
            width: 100%;
            margin-left: 0.5rem;
            border: none;
            outline: none;
            background: transparent;
          }
        `
      }
    }
  })

  editor.BlockManager.add('basic-search', {
    label: 'Search',
    media: `<img src='https://www.svgrepo.com/show/14071/search.svg' />`,
    category: 'Functional',
    content: { type: 'basic-search-component' }
  })
}

export default Basic_Search;