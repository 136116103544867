import { useEffect, useState } from 'react';
import styled from 'styled-components';
import SnippetTypes from '../utils/SnippetTypes';
import BaseModal from '../components/modals/baseModal';
import InitializeClient, { UpdateAnvilConfigSnippet } from '../utils/InitializeClient';
import NewSnippetModal from '../components/modals/newSnippetModal';
import ToggleBox from '../components/ui/toggleBox';

const MenuContainer = styled.div`
  position: absolute;
  top: 0; 
  right: 0; 
  left: 0; 
  bottom: 0; 
  overflow-y: scroll;
  background: var(--ui-light);
  color: var(--ui-black);
  z-index: 100;
  transition: all 222ms;
  opacity: ${p => p.active ? '1' : '0'}; 
  pointer-events: ${p => p.active ? 'all' : 'none'};
  display: flex;
  justify-content: center;
`

const MenuWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
`

const Table = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10rem;
  flex-wrap: wrap;
` 

const Row = styled.div`
  width: 100%;
  display: flex;
  transition: all 222ms;
  cursor: pointer;

  &:hover {
    background: ${p => p.header ? '' : 'var(--ui-info)' };
  }
`

const Cell = styled.div`
  display: flex;
  pointer-events: none;
  border-bottom: 1px solid #3b3b3b;
  width: 100%;
  align-items: center;
  align-content: center;
  padding: 1rem;
  flex-grow: 1;
  text-align: ${p => p.center ? 'center' : 'left'};
  justify-content: ${p => p.center ? 'center' : 'flex-start'};
  font-weight: ${p => p.header ? 'bold' : 'normal'};
  
`

const InitializeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrapper: center;
  align-items: center;
  align-content: center;
  margin: 1rem;
`

const InitializeButton = styled.div`
  padding: 0.5rem 1rem;
  font-size: 22px;
  width: calc(100%);
  text-align: center;
  border-radius: 0.5rem;
  user-select: none;

  cursor: pointer;
  box-shadow: 0 2px 8px var(--uia-dark);
  background: var(--ui-dark);
  color: var(--ui-light);

`

const InputFormWrapper = styled.div`
  width: 100%;
  margin: 1rem 0;
  display: flex;
`

const FormButton = styled.div`
  padding: 0.5rem;
  font-size: 18px;
  text-align: center;
  border-radius: 0.5rem;
  user-select: none;
  display: flex;
  white-space: pre;
  margin-left: 1rem;
  transition: all 222ms;

  box-shadow: 0 0 4px var(--uia-dark);
  background: ${p => !p.active ? 'var(--uia-dark)' : 'var(--ui-dark);'};
  color: ${p => !p.active ? 'var(--uia-light)' : 'var(--ui-light)'};
  cursor: ${p => !p.active ? 'auto' : 'pointer'};
`

const MenuHeader = styled.div`
  font-size: 32px;
  font-weight: bold;
  padding-top: 3rem;
`

const InputForm = styled.input`
  width: 100%;
  padding: 0.5rem;
  box-shadow: 0 0px 2px var(--uia-dark);
  border: none;
  outline: none;
  border-radius: 0.5rem;
  font-size: 18px;
`

const MenuActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const MenuActionButton = styled(FormButton)`
  margin-left: 0;
  margin-right: 1rem;
`

const MenuScreen = (props) => {

  let { refs, state, setState } = props;

  const [gaPropId, setGAPropId] = useState({
    value: '',
    changed: false,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    let config = refs.get.ForgeData();
    setGAPropId(prev => {
      let gap = {...prev}
      gap.value = config.gaPropertyId;
      return gap;
    })
  }, [refs]);

  const RefreshSnippets = () => {
    let Ignite = refs.get.Ignite();
    setState(prev => {
      let stx = {...prev}
      stx.loadingMessage = 'Refreshing Snippet List';
      stx.loading = true;
      return stx;
    })
    Ignite.GetSnippets().then(snippets => {
      refs.set.Snippets(snippets);
      setState(prev => {
        let stx = {...prev}
        stx.loadingMessage = '';
        stx.loading = false;
        return stx;
      })
    })
  }

  const GetSnippetTypeName = (type) => {
    for (let snt of SnippetTypes) {
      if (type === snt.type) {
        return snt.name
      }
    }
    return "Unknown"
  }

  const GetSnippetLastEdited = (snippet) => {
    let date = new Date();
    if (snippet.revisions.length > 0) {
      let revisions = snippet.revisions.sort((a, b) => new Date(b.revisionDate) - new Date(a.revisionDate));
      date = new Date(revisions[0].revisionDate);
    } else {
      date = new Date(snippet.createDate);
    }

    return new Intl.DateTimeFormat('en-us', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(date)
  }

  const GetSnippetLastComment = (snippet) => {
    if (snippet.revisions.length > 0) {
      return snippet.revisions[0].comments;
    } else {
      return '';
    }
  }

  const EditSnippet = async (snippet) => {
    setState(prev => {
      let stx = {...prev}
      stx.loadingMessage = 'Loading Snippet';
      stx.loading = true;
      return stx;
    })

    if (snippet.revisions.length > 0) {
      let revisionData = await refs.get.Ignite().GetSnippetByRevision(snippet.revisions[0].id);
      if (revisionData) {
        refs.set.SelectedRevision(revisionData);
        refs.set.CurrentSnippet(snippet);
      }
    } else {
      refs.set.SelectedRevision(null);
      refs.set.CurrentSnippet(null);
    }
    
    setState(prev => {
      let stx = {...prev}
      stx.menuOpen = false;
      stx.loadingMessage = '';
      stx.loading = false;
      return stx;
    })

  }

  const InitializeAnvil = async () => {
    console.log("initializing");
    setState(prev => {
      let stx = {...prev};
      stx.loadingMessage = 'Initializing Anvil';
      stx.loading = true;
      return stx;
    })

    await new Promise((res, rej) => {
      let t = setTimeout(() =>  {
        clearTimeout(t);
        res();
      }, 222);
    })

    await InitializeClient(refs);
    
    refs.set.Snippets(await refs.get.Ignite().GetSnippets());

    setState(prev => {
      let stx = {...prev};
      stx.loadingMessage = '';
      stx.loading = false;
      return stx;
    })
  }

  const UpdateGAPropertyId = (value) => {
    setGAPropId({
      value,
      changed: (value !== ''),
    })
  } 

  const SaveGAPropertyId = async () => {
    if (!gaPropId.changed) return;
    let config = refs.get.ForgeData();
    config.gaPropertyId = gaPropId.value;
    refs.set.ForgeData(config);
    await UpdateAnvilConfigSnippet(config['anvil-config'], refs);
    setGAPropId({
      value: gaPropId.value,
      change: false,
    })
  } 

  const FilterSnippets = (snippets) => {
    for (let snx of snippets) {
      if (snx.revisions.length > 0) {
        snx['lastUpdate'] = new Date(snx.revisions[0].revisionDate).toISOString();
      } else {
        snx['lastUpdate'] = snx.createdAt;
      }
    }
    // Return snippets sorted by latest revision and whether they fit the search term if there is a search term.
    snippets = snippets.sort((a, b) => new Date(b.lastUpdate) - new Date(a.lastUpdate));
    if (searchTerm && searchTerm !== '') {
      snippets = snippets.filter(s => s.name.toLowerCase().includes(searchTerm));
    }

    // Arbitrarily skip "anvil-config" and "anvil-plugins" so that they can't be edited.
    snippets = snippets.filter(s => s.name !== 'anvil-config');
    snippets = snippets.filter(s => s.name !== 'anvil-plugins');
    snippets = snippets.filter(s => !s.name.includes('noforge'));
    
    return snippets;
  }

  const CreateNewSnippet = async (data) => {

    setModalOpen(false);

    setState(prev => {
      let stx = {...prev}
      stx.loadingMessage = 'Creating New Snippet';
      stx.loading = true;
      return stx;
    })

    try {

      let { snippetName, snippetType, snippetTemplate } = data;
      let Ignite = refs.get.Ignite();
      let snippet = await Ignite.CreateSnippet(snippetName, snippetType);
      let { id } = snippet;
      console.log("Snippet Created", snippet);
      
      let revision = await Ignite.AddRevision(id, 'Created by Forge', snippetTemplate, false);

      console.log("Revision Created", revision);

      refs.set.Snippets(await Ignite.GetSnippets());

      alert("Snippet Created!")
      
    } catch(e) {
      console.error(e)
      
    } finally {
      setState(prev => {
        let stx = {...prev}
        stx.loadingMessage = '';
        stx.loading = false;
        return stx;
      })
    }


  }

  return (
    <>
      <BaseModal 
        name="Create Snippet"
        active={modalOpen}
        modal={NewSnippetModal} 
        onCancel={() => setModalOpen(false)}
        onSubmit={(data) => CreateNewSnippet(data)}
      />
      <MenuContainer active={props.active} branding={refs.get.Branding()} >
        <MenuWrapper>
          <MenuHeader>Forge Settings</MenuHeader>
          {(!refs.get.ForgeData().initialized) ?
            <span>You must create Anvil snippets before you can edit the Forge settings.</span>
          : 
            <>
              <InputFormWrapper>
                <InputForm 
                  title="Your Property ID can be provided by your GEM or Support"
                  onChange={(e) => UpdateGAPropertyId(e.target.value)} 
                  placeholder="Google Analytics Property ID"/>
                <FormButton active={gaPropId.changed} onClick={SaveGAPropertyId}>Create Anvil Snippets</FormButton>
              </InputFormWrapper>
              {/* <ToggleBox  title={'Title'} /> */}
            </>
          }
          
          <MenuHeader>Snippets</MenuHeader>
          
          <Table>
            <InputFormWrapper>
              <InputForm 
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())} 
                placeholder="Search Snippets"/>
            </InputFormWrapper>
            <MenuActionsWrapper>
              <MenuActionButton active onClick={() => setModalOpen(true)}>Create New Snippet</MenuActionButton>
              <MenuActionButton active onClick={() => RefreshSnippets()}>Refresh Snippet List</MenuActionButton>
            </MenuActionsWrapper>
            <Row header>
              <Cell header>Name</Cell>
              <Cell header >Type</Cell>
              <Cell header >Last Edited</Cell>
              <Cell header >Comment</Cell>
            </Row>
            <Row>
              {(!refs.get.ForgeData().initialized) &&
                <InitializeWrapper>
                  <InitializeButton onClick={() => InitializeAnvil()}>Create Anvil Snippets</InitializeButton>
                </InitializeWrapper>
              }
            </Row>
            {FilterSnippets(refs.get.Snippets())
              .sort((a, b) => new Date(GetSnippetLastEdited(a)) - new Date(GetSnippetLastEdited(b)))
              .reverse()
              .map((snx, i) => {
              return (
                <Row key={i} onClick={() => EditSnippet(snx)}>
                  <Cell>{snx.name}</Cell>
                  <Cell>{GetSnippetTypeName(snx.snippetType)}</Cell>
                  <Cell>{GetSnippetLastEdited(snx)}</Cell>
                  <Cell>{GetSnippetLastComment(snx)}</Cell>
                </Row>
              )
            })}
          </Table>
        </MenuWrapper>
      </MenuContainer>
    </>

  )
}

export default MenuScreen;