const Layout_Collapse = (editor) => {
  editor.Components.addType('layout-collapse-component', {
    model: {
      defaults: {

        'expand': true,

        traits: [
          {
            type: 'checkbox',
            name: 'expand',
            label: 'Expand',
            valueTrue: true,
            valueFalse: false,
            changeProp: true,
          }
        ],
        
        'script-props': ['expand'],

        script: function(props) {
          let element = this;
          
          let expand = props.expand;
          
          let button = element.querySelector('.collapse-button');
          let content = element.querySelector('.collapse-content');

          if (expand) {
            button.classList.add('active');
            content.classList.add('active');
          } else {
            button.classLIst.remove('active');
            content.classList.remove('active');
          }

          const onClick = (e) => {
            console.log("Clicked!");
            if (button.classList.contains('active')) {
              button.classList.remove('active');
              content.classList.remove('active');
            } else {
              button.classList.add('active');
              content.classList.add('active');
            }
          }

          console.log('Props changing');
          button.removeEventListener('click', onClick);
          button.addEventListener('click', onClick);
          
        },

        attributes: { class: 'collapse-container' },
        name: 'Collapse',
        tagName: 'div',

        components: [
          {
            tagName: 'div',
            name: 'Collapse Header',
            attributes: { class: 'collapse-header' },
            components: [
              {
                tagName: 'div',
                attributes: { class: 'collapse-header-text' },
                name: 'Header Text',
                components: 'Header'
              },
              {
                tagName: 'div',
                attributes: { class: 'collapse-button' },
                name: 'Collapse Button',
                components: [
                  {
                    type: 'text',
                    draggable: false,
                    name: 'Collapse Button Text',
                    tagName: 'div',
                    components: 'Expand'
                  }, {
                    type: 'text',
                    draggable: false,
                    name: 'Collapse Arrow',
                    tagName: 'div',
                    attributes: { class: 'collapse-arrow' },
                    components: '▼',
                  }
                ]
              }
            ]
          },
          {
            tagName: 'div',
            name: 'Collapse Content',
            attributes: { class: 'collapse-content' }
          }
        ],

        styles: `
          .collapse-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .collapse-header {
            width: 100%;
            padding: 1rem;
            background: white;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            font-family: 'Poppins';
            align-items: center;
          }

          .collapse-header-text {
            font-size: 22px;
            font-weight: bold;
          }

          .collapse-button {
            display: flex;
            padding: 0.5rem;
            background: red;
            border-radius: 8px;
            justify-content: space-between;
            cursor: pointer;
            user-select: none;
          }

          .collapse-button.active .collapse-arrow {
            transition: all 222ms;
            transform: rotateX(180deg);
          }

          .collapse-arrow {
            perspective: 1000px;
            transition: all 222ms;
            transform: rotateX(0deg);
            margin-left: 1rem;
          }
          
          .collapse-content {
            width: 100%;
            padding: 0.5rem 0;
            display: none;
          }

          .collapse-content.active {
            display: flex;
          }

        `
      }
    }
  })

  editor.BlockManager.add('layout-collapse', {
    label: 'Collapse',
    media: `<img src='https://www.svgrepo.com/show/344426/arrows-collapse.svg' />`,
    category: 'Functional',
    content: { type: 'layout-collapse-component' }
  })
}

export default Layout_Collapse;