const Special_Login = (editor) => {

  editor.Components.addType('special-login-component', {
    model: {
      defaults: {

        script: function () {
          let element = this;

          const GetOSName = () => {
            let OSName = "Unknown";
            if (window.navigator.userAgent.indexOf("Windows NT 10.0") !== -1) OSName = "Windows 10";
            if (window.navigator.userAgent.indexOf("Windows NT 6.3") !== -1) OSName = "Windows 8.1";
            if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1) OSName = "Windows 8";
            if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1) OSName = "Windows 7";
            if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1) OSName = "Windows Vista";
            if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1) OSName = "Windows XP";
            if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1) OSName = "Windows 2000";
            if (window.navigator.userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
            if (window.navigator.userAgent.indexOf("X11") !== -1) OSName = "UNIX";
            if (window.navigator.userAgent.indexOf("Linux") !== -1) OSName = "Linux";
            return OSName;
          }

          const GetBrowser = () => {
            var ua = navigator.userAgent;
            var tem;
            var M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if (/trident/i.test(M[1])) {
              tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
              return 'IE ' + (tem[1] || '');
            }
            if (M[1] === 'Chrome') {
              tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
              if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
            M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
            if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
            return M.join(' ');
          }

          let supportEmailSubject = 'Login support for Domain: ' + window.location.host;
          let supportEmailBody = ' | OS: ' + GetOSName() + ' | Browser: ' + GetBrowser();
          let supportEmailHref = 'mailto:support@nextechar.com?subject=' + supportEmailSubject+supportEmailBody;
          let supportEmail = element.querySelector('.support-email')
          supportEmail.setAttribute('href', supportEmailHref);
        },

        name: 'Login Container',
        tagName: 'div',
        attributes: {id: 'login-container'},

        components: [
          {
            name: 'Wrapper',
            tagName: 'div',
            attributes: { class: 'login-wrapper' },
            components: [
              {
                name: 'Form Header',
                tagName: 'div',
                attributes: { class: 'form-header' },
                components: 'Log In',
              },
              {
                name: 'Form Text',
                tagName: 'div',
                attributes: { class: 'form-text' },
                components: [
                  {
                    tagName: 'div',
                    components: [
                      'Not yet registered? ',
                      {
                        tagName: 'a',
                        type: 'link',
                        attributes: { class: 'form-link', id: 'register-link' },
                        components: 'Register'
                      }
                    ]
                  }
                ]
              },
              {
                name: 'Form',
                tagName: 'form',
                attributes: { class: 'login-form', id: 'login' },
                components: [
                  {
                    name: 'Email Field',
                    tagName: 'input',
                    attributes: { 
                      class: 'email-field form-field', 
                      name: 'email', 
                      type: 'email', 
                      id: 'login-username', 
                      placeholder: 'Email',
                    },
                  }, {
                    name: 'Login Error',
                    tagName: 'div',
                    attributes: { class: 'email-error' },
                    components: 'There was an email error.',
                  }, {
                    name: 'Password Field',
                    tagName: 'input',
                    attributes: { 
                      class: 'password-field form-field', 
                      name: 'password', 
                      type: 'password', 
                      id: 'login-password', 
                      placeholder: 'Password',
                    },
                  }, {
                    name: 'Password Error',
                    tagName: 'div',
                    attributes: { class: 'password-error' },
                    components: 'There was a password error.',
                  }, {
                    name: 'Form Button',
                    tagName: 'button',
                    attributes: { class: 'submit-button form-button', type: 'submit', id: 'submit-btn' },
                    components: 'Log In'
                  }, {
                    name: 'Form Error',
                    tagName: 'div',
                    attributes: { class: 'form-error' },
                    components: 'There was a form error.',
                  }, 
                ],

              },
              {
                name: 'Forgot Password Link',
                tagName: 'a',
                type: 'link',
                attributes: { href: '/forgotpassword', class: 'form-link' },
                components: 'Forgot Password?'
              },
              {
                name: 'Support Section',
                tagName: 'div',
                attributes: { class: 'support-links' },
                components: [
                  {
                    type: 'text',
                    components: 'If you require support, please email '
                  },
                  {
                    name: 'Support Email',
                    tagName: 'a',
                    type: 'link',
                    attributes: { href: 'mailto:support@nextechar.com', class: 'form-link support-email' },
                    components: 'support@nextechar.com'
                  },
                ]
              },
              {
                name: 'Support Section',
                tagName: 'div',
                attributes: { class: 'support-links' },
                components: [
                  {
                    type: 'text',
                    components: 'Or call '
                  },
                  {
                    name: 'Support Email',
                    tagName: 'a',
                    type: 'link',
                    attributes: { href: 'tel:18663306655', class: 'form-link' },
                    components: '+1.866.330.6655'
                  },
                ]
              },
            ]
          }
        ],

        styles: `
          #login-container {
            display:flex;
            justify-content:center;
            font-family: "Poppins";
          }
          
          .login-wrapper {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0.5rem;
            color: #030035;
            font-family: "Poppins";
          }

          .login-form {
            display: flex;
            flex-direction: column;
          }

          .form-field {
            margin: 0.5rem 0;
            padding: 0.5rem;
            border: 1px solid #03003588;
            border-radius: 6px;
            font-size: 18px;
            outline: none;
          }

          .form-header {
            font-size: 22px;
            font-weight: bold;
          }

          .form-button {
            font-size: 18px;
            padding: 0.5rem;
            background: #030035;
            color: #fff;
            font-weight: bold;
            border: none;
            border-radius: 6px;
            margin: 1rem 0;
            outline: none;
            box-shadow: 4px 4px 10px #201e244d;
            user-select: none;
            cursor-pointer;
          }

          .form-text {
            margin: 2rem 0;
          }

          .form-link {
            color: #030035 !important;
            text-decoration: underline;
            cursor: pointer;
          }

          .form-link:hover {
            color: #030035 !important;
            text-decoration: underline;
            cursor: pointer;
          }

          .support-links {
            margin-top: 1rem;
          }

        `
      }
    }
  })

  editor.BlockManager.add('login', {
    label: 'Login Form',
    media: `<img src='https://www.svgrepo.com/show/141471/login.svg' />`,
    category: 'Special',
    content: { type: 'special-login-component' }
  })


}

export default Special_Login;