import { useEffect, useRef, useState } from "react";

import LoadingScreen from "./screens/loadingScreen";
import ErrorScreen from "./screens/errorScreen";
import MenuScreen from "./screens/menuScreen";
import Editor from "./components/editor";

import defaultIgniteLogo from './resources/defaultIgniteLogo';
import defaultIgniteSmallLogo from './resources/defaultIgniteSmallLogo';

import jwt_decode from 'jwt-decode';
import IgniteAPI from "./utils/IgniteAPI";
import { GetAnvilConfig } from "./utils/InitializeClient";

import styled from "styled-components";
import { darken, lighten } from 'polished';

// Set up container for branding-specific variables.
const AppWrapper = styled.div`
  transition: all 222ms;
  height: 100%;

  --brand-background-color: ${p => p.branding.navbarBackgroundColor};
  --brand-color: ${p => p.branding.navbarColor};
  --brand-background-highlight-color: ${p => p.branding.navbarHighlightBackgroundColor};
  --brand-highlight-color: ${p => p.branding.navbarHighlightColor};
  --brand-text-color: ${p => p.branding.navbarTextColor};

  --alpha-brand-background-color: ${p => p.branding.navbarBackgroundColor+'88'};
  --alpha-brand-color: ${p => p.branding.navbarColor+'88'};
  --alpha-brand-background-highlight-color: ${p => p.branding.navbarHighlightBackgroundColor+'88'};
  --alpha-brand-highlight-color: ${p => p.branding.navbarHighlightColor+'88'};
  --alpha-brand-text-color: ${p => p.branding.navbarTextColor+'88'};

  --light-brand-background-color: ${p => lighten(0.15, p.branding.navbarBackgroundColor)};
  --light-brand-color: ${p => lighten(0.15, p.branding.navbarColor)};
  --light-brand-background-highlight-color: ${p => lighten(0.15, p.branding.navbarHighlightBackgroundColor)};
  --light-brand-highlight-color: ${p => lighten(0.15, p.branding.navbarHighlightColor)};
  --light-brand-text-color: ${p => lighten(0.15, p.branding.navbarTextColor)};

  --dark-brand-background-color: ${p => darken(0.15, p.branding.navbarBackgroundColor)};
  --dark-brand-color: ${p => darken(0.15, p.branding.navbarColor)};
  --dark-brand-background-highlight-color: ${p => darken(0.15, p.branding.navbarHighlightBackgroundColor)};
  --dark-brand-highlight-color: ${p => darken(0.15, p.branding.navbarHighlightColor)};
  --dark-brand-text-color: ${p => darken(0.15, p.branding.navbarTextColor)};

  --ui-light: #F5F5F5;
  --uia-light: #F5F5F588;
  
  --ui-success: #d0e6a5;
  --uia-success: #d0e6a588;
  
  --ui-info: #86e3ce;
  --uia-info: #86e3ce88;
  
  --ui-warning: #ffdd94;
  --uia-warning: #ffdd9488;
  
  --ui-danger: #fa897b;
  --uia-danger: #fa897b88;
  
  --ui-help: #ccabd8;
  --uia-help: #ccabd888;
  
  --ui-dark: #30475E;
  --uia-dark: #30475E88;
  
  --ui-black: #121212;
  --uia-black: #12121288;
  

  .gjs-block__media {
    filter: invert(1);

    & img {
      width: 42px; 
      height: 42px; 
      pointer-events: none;
    }
  }

  .gjs-one-bg {
    background: #30475E;
  }

  .gjs-three-bg {
    background: #F05454;
  }

  .gjs-pn-btn {
    width: 100% !important;
  }

  .gjs-four-color {
    color: #F05454;
  }

  .gjs-four-color-h:hover {
    color: #F05454;
  }

`

const App = () => {

  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUp6ZFdJaU9pSmtPVGs1WXpoaU1pMWxZakF3TFRRellqSXRPVGcwTmkwMllqTXpOR1E0Wm1JNU1tVWlMQ0pxZEdraU9pSmhaRFExTmpaaE5DMWhObVJtTFRSbE1EZ3RPVFpqWkMxbE56YzBaRGMyWlRkall6TWlMQ0pwWVhRaU9qRTJNemc1TXpRMU16Y3NJa1YyWlc1MFVtVm5WWE5sY2lJNlptRnNjMlVzSWtOc2FXVnVkRWxrSWpvaU5qbGtNemRrTVdJdFlUUTFOaTAwWVRJNExUSTJZelF0TURoa09HUTROekF4WXpsaUlpd2lSVzFoYVd3aU9pSnFaWE56WlM1cmIyTm9RRzVsZUhSbFkyaGhjaTVqYjIwaUxDSkpjMUp2YjNRaU9tWmhiSE5sTENKdVltWWlPakUyTXpnNU16UTFNellzSW1WNGNDSTZNVFl6T0Rrek9ERXpOaXdpYVhOeklqb2lOak5pWldZelkyUmhNelU1TkdabU1HRTVOMkpoWVdKaFltTmhORE00T0dZaUxDSmhkV1FpT2lKSmJtWmxjbTV2UTI5eVpTSjkuQkRjdzFFdWZWdlFUbXdzOVFvck9NalBpTGlVSDFlNUljcmYzc3RBd3V0YyIsImNsaWVudElkIjoiNjlkMzdkMWItYTQ1Ni00YTI4LTI2YzQtMDhkOGQ4NzAxYzliIiwib3ZlcnJpZGVJZCI6IjllOTMxMDViLTQ5NzAtNGE2My0xMTg0LTA4ZDc3OGY5MDM2YiIsImVtYWlsIjoiamVzc2Uua29jaEBuZXh0ZWNoYXIuY29tIn0.zGhb3TKx6r_RDVh4DBc3Sx2WthDqani0z2fvv_PKKWY
  // http://localhost:3000/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUp6ZFdJaU9pSmtPVGs1WXpoaU1pMWxZakF3TFRRellqSXRPVGcwTmkwMllqTXpOR1E0Wm1JNU1tVWlMQ0pxZEdraU9pSmhaRFExTmpaaE5DMWhObVJtTFRSbE1EZ3RPVFpqWkMxbE56YzBaRGMyWlRkall6TWlMQ0pwWVhRaU9qRTJNemc1TXpRMU16Y3NJa1YyWlc1MFVtVm5WWE5sY2lJNlptRnNjMlVzSWtOc2FXVnVkRWxrSWpvaU5qbGtNemRrTVdJdFlUUTFOaTAwWVRJNExUSTJZelF0TURoa09HUTROekF4WXpsaUlpd2lSVzFoYVd3aU9pSnFaWE56WlM1cmIyTm9RRzVsZUhSbFkyaGhjaTVqYjIwaUxDSkpjMUp2YjNRaU9tWmhiSE5sTENKdVltWWlPakUyTXpnNU16UTFNellzSW1WNGNDSTZNVFl6T0Rrek9ERXpOaXdpYVhOeklqb2lOak5pWldZelkyUmhNelU1TkdabU1HRTVOMkpoWVdKaFltTmhORE00T0dZaUxDSmhkV1FpT2lKSmJtWmxjbTV2UTI5eVpTSjkuQkRjdzFFdWZWdlFUbXdzOVFvck9NalBpTGlVSDFlNUljcmYzc3RBd3V0YyIsImNsaWVudElkIjoiNjlkMzdkMWItYTQ1Ni00YTI4LTI2YzQtMDhkOGQ4NzAxYzliIiwib3ZlcnJpZGVJZCI6IjBhMWExMjQ0LWMwMjItNDMyNi1jYzE4LTA4ZDlhYWM1YzIyMiIsImVtYWlsIjoiamVzc2Uua29jaEBuZXh0ZWNoYXIuY29tIn0.lT-VvdpeVBrPmPtzlnT8yCkmfbtABQyv9mCndzxtEYU
  // FIXME Get new snippets list after save.
  // TODO Get latest revision after loading all snippets to check if they're an Anvil compatible snippet.
  // TODO Build Dynamic Components Framework.

  const [state, setState] = useState({
    loading: true,
    loadingMessage: 'Loading',
    error: null,
    menuOpen: true,
  });

  const SelectedTemplate = useRef(null);
  const Ignite = useRef(null);
  const Snippets = useRef([]);
  const CurrentSnippet = useRef(null);
  const SelectedRevision = useRef(null);
  const Branding = useRef({
    "id": "5478a468-93bb-407a-be71-e9d7366d8534",
    "categoryId": "00000000-0000-0000-0000-000000000000",
    "clientId": "0a1a1244-c022-4326-cc18-08d9aac5c222",
    "logo": defaultIgniteLogo,
    "smallLogo": defaultIgniteSmallLogo,
    "primaryFontColor": null,
    "navbarColor": "#2846C6",
    "navbarTextColor": "#515253",
    "navbarHighlightColor": "#2846C6",
    "navbarBackgroundColor": "#f5f7fa",
    "navbarHighlightBackgroundColor": "#eff2f5",
    "sidebarDefaultState": 0,
    "defaultHostname": null,
    "showSearch": false
  });
  const ForgeData = useRef({
    initialized: false,
    gaPropertyId: "",
    'anvil-config': null,
    'anvil-header': null,
    'anvil-footer': null,
    'anvil-plugins': null,
  })

  const Refs = {
    get: {
      Ignite: () => Ignite.current,
      Snippets: () => Snippets.current,
      CurrentSnippet: () => CurrentSnippet.current,
      SelectedRevision: () => SelectedRevision.current,
      Branding: () => Branding.current,
      ForgeData: () => ForgeData.current,
      SelectedTemplate: () => SelectedTemplate.current,
    },
    set: {
      Ignite: (newRef) => Ignite.current = newRef,
      Snippets: (newRef) => Snippets.current = newRef,
      CurrentSnippet: (newRef) => CurrentSnippet.current = newRef,
      SelectedRevision: (newRef) => SelectedRevision.current = newRef,
      Branding: (newRef) => Branding.current = newRef,
      ForgeData: (newRef) => ForgeData.current = newRef,
      SelectedTemplate: (newRef) => SelectedTemplate.current = newRef,
    },
  }

  useEffect(() => {
    // Get token from URL
    setState(prev => {
      prev.loadingMessage = 'Parsing Token'
      return prev;
    })
    let token = new URLSearchParams(window.location.search).get('token');
    // Set error if invalid or null.
    if(!token) {
      setState(prev => {
        let stx = {...prev}
        stx.error = "Missing Token"
        return stx;
      })
      return;
    }
    token = JSON.parse(decodeURI(token));
    // Initialize IgniteAPI Class
    setState(prev => {
      prev.loadingMessage = 'Starting IgniteX API'
      return prev;
    })
    Ignite.current = new IgniteAPI(token);

    setState(prev => {
      prev.loadingMessage = 'Configuring AWS'
      return prev;
    })
    // AWS.config.region = 'us-west-2';
    // AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    //   IdentityPoolId: 'us-west-2:5b8eea35-ea99-4be7-821a-d1a1629fcc27',
    // });

    // Bucket.current = {
    //   bucketName: 'forge-cdn',
    //   dirName: token.overrideId ?? token.clientId,
    //   region: 'us-east-1',
    //   accessKeyId: 'AKIA3EBNTLTCZKGFCJT6',
    //   secretAccessKey: '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',
    //   //s3Url: 'cdn.forge.nextechar.com',
    // }

    // Wait until ready then continue setup.
    new Promise(async (resolve, reject) => {
      // Fetch Data from API
      await Ignite.current.Ready();

      Snippets.current = await Ignite.current.GetSnippets();

      // Get Client Branding Options

      let client = token.overrideId ?? token.clientId;
      let branding = await Ignite.current.GetBrandingByClient(client);
      if (branding.length > 0) {
        Branding.current = branding[0];
      }
      
      Refs.set.ForgeData(await GetAnvilConfig(Refs));

      // console.log(Snippets.current);

      // await InitializeClient(Refs);

      // Clear Loading
      setState(prev => {
        let stx = {...prev};
        stx.loading = false;
        return stx;
      })

      resolve();
    })
  }, [])

  return (
    <AppWrapper branding={Branding.current}>
      <LoadingScreen active={state.loading} message={state.loadingMessage} />
      <ErrorScreen active={state.error ? true : false} error={state.error} />
      <MenuScreen 
        active={state.menuOpen} 
        refs={Refs}
        state={state}
        setState={setState}
      />
      {!state.menuOpen && 
        <Editor 
          refs={Refs} 
          state={state}
          setState={setState}
        />
      }
    </AppWrapper>  
  );
}

export default App;
