export default class IgniteAPI {
  
  ready = false
  
  constructor(data) {
    this.Setup(data);
  }

  async Setup(data) {
    this.apiUrl = 'https://prod-eastus-ingress.inferno.jolokia.com/api';
    this.email = data.email;

    this.headers = {
      Authorization: `Bearer ${data.token}`,
    }
    
    if (data.overrideId) {
      let domain = await fetch(`${this.apiUrl}/ClientDomains/GetByClient/${data.overrideId}`, {
        method: "GET",
        headers: this.headers
      }).then(res => res.json());
      this.headers['X-Client-Override'] = data.overrideId;
      this.headers['X-InfernoCore-Domain'] = domain[0].hostname
    } else {
      let domain = await fetch(`${this.apiUrl}/ClientDomains/GetByClient/${data.clientId}`, {
        method: "GET",
        headers: this.headers
      }).then(res => res.json());
      this.headers['X-InfernoCore-Domain'] = domain[0].hostname
    }

    this.GET = { method: 'GET', withCredentials: true, headers: this.headers };
    this.POST = { method: 'POST', withCredentials: true, headers: this.headers };
    this.PUT = { method: 'PUT', withCredentials: true, headers: this.headers };
    this.DELETE = { method: 'DELETE', withCredentials: true, headers: this.headers };

    this.ready = true;
  }

  /*******************************************
   * Branding
   */
  async GetBrandingByClient(clientId) {
    let branding = await fetch(`${this.apiUrl}/Branding/GetAllForClient/${clientId}`);
    return this.Process(branding, 'GetBrandingByClient');
  }

  /*******************************************
   * Client Options
   */
  async GetClientOptionsByClient(clientId) {
    let options = await fetch(`${this.apiUrl}/ClientOptions/GetByClient/${clientId}`);
    return this.Process(options, 'GetClientOptionsByClient');
  }

  /*******************************************
   * Snippets
   */
  async GetSnippets() {
    let snippets = await fetch(`${this.apiUrl}/Snippets`, this.GET);
    return this.Process(snippets, 'GetSnippets');
  }

  async GetSnippetById(id) {
    let snippet = await fetch(`${this.apiUrl}/Snippets/${id}`, this.GET);
    return this.Process(snippet, 'GetSnippetById');
  }

  async GetSnippetByRevision(revisionId) {
    let snippet = await fetch(`${this.apiUrl}/Snippets/GetRevision/${revisionId}`, this.GET);
    return this.Process(snippet, 'GetSnippetByRevision')
  }

  async CreateSnippet(name, type)  {
    let data = {
      "id": "00000000-0000-0000-0000-000000000000",
      "name": name,
      "snippetType": type,
      "createDate": new Date().toISOString()
    }

    let request = this.POST;
    request.headers["Content-Type"] = "application/json";
    request.headers["Accept"] = "application/json";
    request["body"] = JSON.stringify(data);

    let snippet = await fetch(`${this.apiUrl}/Snippets/AddSnippet`, request);
    return this.Process(snippet);
  }

  async AddRevision(snippetId, comment, data, isJson = false) {

    let headerData = {
      "codeSnippetId": snippetId,
      "comments": comment,
      "snippet": (isJson) ? JSON.stringify(data) : data,
    }

    let request = this.POST;
    request.headers["Accept"] = "application/json";
    request.headers["Content-Type"] = "application/json";
    request["body"] = JSON.stringify(headerData);

    let revision = await fetch(`${this.apiUrl}/Snippets/AddRevision`, request);
    return this.Process(revision);

  }

  /**********************************************
   * Post Processing
   */

  async Process(results, callingMethod) {
    try {
      return await results.json();
    } catch (e) {
      console.error(["There was a problem processing the results as JSON. ", e, "Received: " + results.status + ": " + results.statusText], callingMethod, "error");
    }
  }

  async Ready() {
    await new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (this.ready) {
          clearInterval(interval);
          resolve(true);
        }
      }, 100);
    })
  }
}